<template>
    <div>
        <Modal ref="modal" :childclass="{'no-content-padding':true, 'tabbed-modal':true}" disable-content m-width="780px">
            <template v-slot:belowContent>
                <div id="modal-c">
                    <div id="tabs">
                        <h1>{{ mTitle }}</h1>
                        <b-button :variant="page == selectedTab ? 'primary' : 'transparent'"
                                  v-for="(name, page) in pages" :key="page" @click="setTab(page)">
                            {{ name }}
                        </b-button>
                    </div>
                    <div id="contents">
                        <div v-for="(pageName, page) of pages" :key="page">
                            <div v-if="page == selectedTab" v-animate-css="{classes: 'fadeIn', duration: 300}" >
                                <slot :name="`page-${page}`" />
                            </div>
                        </div>
                        <slot/>
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'

export default {
    components: { Modal },
    props: {
        pages: { type: Object },
        mTitle: { type: String }
    },
    data: function () {
        return {
            selectedTab: Object.keys(this.pages)[0]
        }
    },
    methods: {
        open() {
            this.$refs.modal.open()
        },
        setTab(tab) {
            this.selectedTab = tab
            this.$emit('tabChanged', tab)
        }
    }
}
</script>
<style lang="scss" scoped>
.tabbed-modal {
    #modal-c {
        width: 810px;
        max-width: 100%;
    }

    #tabs {
        position: absolute;
        display: inline-block;
        width: 257px;
        vertical-align: top;
        background: #00000007;
        border-radius: 10px;
        padding: 20px;
        height: 100%;

        h1 {
            font-weight: 600;
            font-size: 21px !important;
            padding-left: 7px;
            padding-top: 5px;
            user-select: none;
        }

        button {
            display: block;
            width: 100%;
            margin-bottom: 5px;
            padding: 4px 11px;
            text-align: left;
            font-size: 17px;
        }

    }

    #contents {
        display: inline-block;
        vertical-align: top;
        min-height: 425px;
        padding: 20px;
        font-size: 21px;
        width: calc(100% - 277px);
        margin-left: 267px;
        height: 480px;
        overflow-y: auto;
    }

    .tabbed-modal {
        #tabs {
            position: relative;
            height: inherit;
            padding: 15px 10px 0;
            margin: -10px;
            width: calc(100% + 20px);
        }

        #contents {
            width: 100%;
            display: block;
            margin-left: 0;
            min-height: 0;
        }
    }

    @media screen and (max-width: 720px) {
        #tabs {
            position: initial;
            width: 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        #contents {
            position: initial;
            left: 0;
            width: 100%;
            margin-left: 0;

        }

    }
}
</style>
