<template>
    <div class="collapsable-search-button" :class="{collapsed: collapsed && !forceNotCollapsed}" @click="collapse">
        <input ref="searchInput" @focusout.prevent.stop="focusOut" @keydown.esc="close" type="text" :placeholder="placeholder" v-model="query">
        <i class="uil uil-times" @click.prevent.stop="clearButton" />
        <search-icon />
    </div>
</template>
<script>
import SearchIcon from '@/components/icons/SearchIcon'

export default {
    components: { SearchIcon },
    data: () => ({
        query: '',
        collapsed: false,
        forceNotCollapsed: false
    }),
    props: {
        value: { type: String },
        placeholder: { type: String, default: '' }
    },
    mounted() {
        this.query = this.value
    },
    watch: {
        query(to) {
            this.$emit('input', to);
        }
    },
    methods: {
        collapse() {
            if (!this.collapsed) {
                this.collapsed = true
                this.$refs.searchInput.focus()
            }
        },
        close() {
            this.query = ''
            this.collapsed = false
        },
        clearButton() {
            if (this.query == '') {
                this.collapsed = false
            } else {
                this.query = ''
            }
        },
        focusOut() {
            if (this.query == '' && !this.forceNotCollapsed && this.collapsed) {
                this.forceNotCollapsed = true
                this.collapsed = false
                setTimeout(() => {
                    this.collapsed = false
                    this.forceNotCollapsed = false
                }, 250)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
.collapsable-search-button {
    display: inline-block;
    border-radius: 6px;
    height: 31px;
    background: $primary;
    transition: 0.3s;
    cursor: pointer;

    i.uil-times, svg {
        vertical-align: middle;
        margin: 0 5px;
        line-height: 31px;
    }

    i.uil-times {
        color: $textDefault;
        font-size: 23px;
        display: none;
        cursor: pointer;
    }

    svg {
        margin-top: 4px;
        display: inline-block;
        width: 20px;
        height: 20px;
        fill: currentColor;
        color: #FFF;
    }

    input {
        vertical-align: middle;
        background: transparent;
        border: none;
        outline: none;
        width: 200px;
        max-width: 0;
        transition: 0.3s;
        &::placeholder {
            color: transparent;
        }
    }

    &:hover {
        background: #a10003;
    }

    &.collapsed {
        background: #0000000A;
        cursor: text;
        input {
            max-width: 200px;
            padding-left: 10px;
            &::placeholder {
                color: $textDefault;
            }
        }
        i.uil-times {
            display: inline-block;
        }
        svg {
            display: none;
        }
    }
}
</style>
