<template>
    <div>
        <b-button :to="{name: 'server-overview', params: {id: entry.id}}" @click="menu.close()" variant="transparent">{{ _('visit') }}</b-button>
        <b-button :to="{name: 'server-charts', params: {id: entry.id}}" @click="menu.close()" variant="transparent">{{ _('servers.tabs.charts') }}</b-button>
        <b-button :to="{name: 'server-backups', params: {id: entry.id}}" @click="menu.close()" variant="transparent">{{ _('servers.tabs.backups') }}</b-button>
        <b-button :to="{name: 'server-actions', params: {id: entry.id}}" @click="menu.close()" variant="transparent">{{ _('servers.tabs.actions') }}</b-button>
        <b-button :to="{name: 'server-settings', params: {id: entry.id}}" @click="menu.close()" variant="transparent">{{ _('servers.tabs.settings') }}</b-button>
        <b-button @click="copyStringToClipboard(entry.id); menu.close()" variant="transparent">{{ _('datatables.copyId') }}</b-button>

        <b-button @click="deleteServer()" variant="transparent">{{ _('delete') }}</b-button>
    </div>
</template>

<script>
import { confirmationPrompt, copyStringToClipboard } from '@/helper'

export default {
    props: {
        entry: { type: Object },
        menu: { type: Object }
    },
    methods: {
        copyStringToClipboard,
        async deleteServer() {
            await confirmationPrompt(this._('servers.reinstallConfirmation', [this.entry.name]))
            await this.computeApi.deleteServer(this.entry.id)
            this.$emit('deleted')
        }
    }
}
</script>
