const meta = {
    category: "servers",
    noAutoTitle: true
}

export default [
    {
        path: '/firewall/:id',
        name: 'firewall',
        meta,
        component: () => import('@/views/resources/firewalls/Firewall'),
        children: [
            {
                name: "firewall-overview",
                path: "",
                component: () => import('@/views/resources/firewalls/Overview'),
                meta
            },
            {
                name: "firewall-rules",
                path: "rules",
                component: () => import('@/views/resources/firewalls/Rules'),
                meta
            },
            {
                name: "firewall-members",
                path: "members",
                component: () => import('@/views/resources/firewalls/Members'),
                meta
            }
        ]
    }
]
