export function copyStringToClipboard(str) {
    let el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export function functionOrDirect(t, args) {
    return typeof t == 'function' ? t.call(null, ...(typeof args == 'function' ? args() : args)) : t
}

export function wait(millis) {
    return new Promise(r => setTimeout(r, millis))
}

export function setPageTitle(text) {
    document.title = `${text} | LUMASERV Cloud Dashboard`
}

export function findFlagUrlByIso2Code(code) {
    return require(`svg-country-flags/svg/${code.toLowerCase()}.svg`)
}

export function generateColorByString(str) { // https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
    if (!str)
        return '';
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const c = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();
    return '#00000'.substring(0, 6 - c.length) + c
}

export * from './transformers'
export * from './theming'
export * from './prompts'
export * from './api'
export * from './projects'