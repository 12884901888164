import store from '@/store'
import router from '@/router'
import { checkProject } from '@/main'
import eventBus from '@/eventBus'


export async function changeProject(id, redirect = true) {
    localStorage["current_project"] = id

    if (router.currentRoute.name === 'project' && redirect) {
        router.push({ name: 'project', params: { id } })
    }

    await checkProject()

    let route = { name: 'project', params: { id } }
    if (router.currentRoute.meta) {
        switch (router.currentRoute.meta.category) {
        case 'domains':
            route = { name: 'domains' }
            break
        case 'servers':
            route = { name: 'servers' }
            break
        }
    }

    eventBus.$emit('projectChanged')
    eventBus.$emit('loadSidenavContents')

    if (redirect && router.currentRoute.name !== 'project') {
        await router.push(route)
    }
}


export async function resourceAutoChangeProject(resourceProject) {
    if (resourceProject && store.state.currentProject.id !== resourceProject) {
        await changeProject(resourceProject, false)
    }
}