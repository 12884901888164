<template>
    <div id="search" :class="{opened}" @click.stop="opened = false; search = ''">
        <div id="search-input-preview" @click.stop="opened = true; selected = 1; focusSearch()">
            <span class="in" @input="opened = true" type="text">{{ _('search._') }}</span>
            <span id="shortcut-name">
                {{ _('search.shortcut') }}
            </span>
        </div>

        <button v-if="opened" id="search-close-button" v-animate-css="{classes: 'fadeIn', duration: 200}">
            <i class="uil uil-multiply" />
            <span>ESC</span>
        </button>

        <div id="search-input" @click.stop="opened = true" v-animate-css="{classes: 'fadeIn', duration: 200}">
            <input @input="opened = true; selected = 1" ref="searchInput" v-model="search" type="text"
                   :placeholder="_('search._')">
        </div>
        <div id="results" ref="results" v-if="opened" @click.stop v-animate-css="{classes: 'fadeIn', duration: 200}">
            <div id="results-container" @click.stop>
                <h1 v-if="entries.length === 0" style="text-align: center; margin: 28px; font-size: 23px">
                    {{ _('search.noEntriesFound') }}</h1>
                
                <template v-for="(entry, i) of entries">
                    <h2 v-if="typeof entry == 'string'" :key="`search-entries-${i}`" ref="entry">{{ entry }}</h2>
                    <a
                        v-else
                        ref="entry"
                        :key="`search-entries-${i}`"
                        :class="{selected: i === selected}"
                        @click.prevent.stop="entry.click(); opened = false; search = ''"
                    >
                        <i :class="['uil', `uil-${entry.icon}`]" />
                        <span>
                            {{ entry.name }}
                            <span v-if="entry.moreInfo" style="opacity: 0.3">{{ entry.moreInfo }}</span>
                        </span>

                        <span v-if="entry.actionInfo" class="action-info">{{ entry.actionInfo }}</span>
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import eventBus from '@/eventBus'
import { changeProject } from '@/helper'

export default {
    data: () => ({
        opened: false,
        search: '',
        selected: 0,
        entries: [],
        lastSearch: ''
    }),
    timers: {
        input: {
            autostart: true,
            time: 500,
            repeat: true,
            callback() {
                if (this.lastSearch != this.search) {
                    this.loadSearch(this.search)
                    this.lastSearch = this.search
                }
            }
        }
    },
    mounted() {
        window.addEventListener('keydown', e => {
            if (e.target && ['INPUT', 'TEXTAREA'].includes(e.target.tagName) && !this.opened) {
                return;
            }

            if (e.key === '/') {
                this.opened = true
                this.selected = 1
                e.preventDefault()
                this.focusSearch()
            } else if (this.opened) {
                let skippedTitle = false

                if (e.keyCode === 40 && this.selected + 2 <= this.entries.length) {
                    this.selected++
                    if (typeof this.entries[this.selected] === 'string') {
                        this.selected++
                        skippedTitle = true
                    }

                    const nextElement = this.$refs.entry[this.selected]
                    // element.height + margin + extra spacing if a title has been skipped
                    const scrollBy = nextElement.clientHeight + 11 + (skippedTitle ? 33.5 : 0)

                    // If we are reaching the most bottom visible element we want to scroll the height of the element, so we can see the next entry
                    if (this.$refs.entry[this.selected].offsetTop > this.$refs.results.clientHeight + this.$refs.results.scrollTop - scrollBy / 1.5) {
                        this.$refs.results.scrollBy(0, scrollBy)
                        if (this.selected === this.entries.length - 1) {
                            this.$refs.results.scrollBy(0, scrollBy)
                        }
                    }
                } else if (e.keyCode === 38 && this.selected - 1 >= 1) {
                    this.selected--
                    if (typeof this.entries[this.selected] === 'string') {
                        this.selected--
                        skippedTitle = true
                    }

                    const nextElement = this.$refs.entry[this.selected]
                    // element.height + margin + extra spacing if a title has been skipped
                    const scrollBy = nextElement.clientHeight + (skippedTitle ? 33.5 : 0)

                    // If we are reaching the most top visible element we want to scroll the height of the element up, so we can see the last entry
                    if (this.$refs.entry[this.selected].offsetTop < this.$refs.results.scrollTop + scrollBy / 1.5) {
                        this.$refs.results.scrollBy(0, -scrollBy)
                    }
                } else if (e.keyCode === 13) {
                    this.opened = false;
                    this.search = ""
                    this.entries[this.selected].click()
                } else if (e.keyCode === 27) {
                    this.opened = false
                    this.search = ""
                }
            }
        });

        eventBus.$on('search', query => {
            this.search = query
        })
    },
    watch: {
        opened(to) {
            if (to) {
                this.loadSearch(this.search)
            }
        }
    },
    methods: {
        async loadSearch(query) {
            query = query.toLowerCase()
            let resources = ['domains', 'domain_handles', 'servers', 'server_firewalls', 'server_volumes']

            if (query.includes(":")) {
                resources = [query.replace('domain:', 'domains:').replace('handle:', 'handles:').replace('server:', 'servers:').split(":")[0].replace('handles', 'domain_handles')]
                query = query.split(":")[1].trim()
            }

            let filterActions = query.startsWith('>')

            if (filterActions) {
                query = query.substring(1).trim()
            }

            const results = (await this.computeApi.search({
                search: query,
                resources: resources.join(","),
                limit: 10
            })).data
            this.entries = []


            this.$refs.results.scrollTo(0, 0)

            if (!filterActions) {

                this.addSearchedResources(results.servers, this._('servers.plural'), server => ({
                    icon: 'server',
                    name: `${server.name}`,
                    moreInfo: server.addresses.map(a => a.address)[0],
                    click: () => {
                        this.$router.push({ name: 'server-overview', params: { id: server.id } });
                    }
                }))

                this.addSearchedResources(results.server_firewalls, this._('firewalls.plural'), firewall => ({
                    icon: 'wall',
                    name: firewall.title,
                    click: () => {
                        this.$router.push({ name: 'firewall-overview', params: { id: firewall.id } });
                    }
                }))


                this.addSearchedResources(results.server_volumes, this._('volumes.plural'), volume => ({
                    icon: 'cloud-database-tree',
                    name: volume.title,
                    click: () => {
                        this.$router.push({ name: 'volume', params: { id: volume.id } });
                    }
                }))

                if (this.$store.state.projects && (resources.length > 1 || resources[0].includes('project'))) {
                    const projects = this.$store.state.projects.filter(p => p.title.toLowerCase().includes(query.toLowerCase()))


                    this.addSearchedResources(projects, this._('project.plural'), project => ({
                        icon: "users-alt",
                        name: project.title,
                        click() {
                            changeProject(project.id)
                        }
                    }))
                }
            }

            for (const catEntries of Object.values(this.$store.state.contextBasedSearchEntries)) {
                const actions = []
                for (const entry of catEntries()) {
                    if (
                        this.compareSearch(entry.name, query) ||
                        this.compareSearch(entry.moreInfo, query) ||
                        this.compareSearch(entry.actionInfo, query) ||
                        this.compareSearch((entry.keywords   || []).join(' '), query)
                    ) {
                        actions.push(entry)
                    }
                }
                if (actions.length > 0) {
                    this.entries.push(this._('actions'))
                    actions.forEach(a => this.entries.push(a))
                }
            }
        },
        focusSearch() {
            setTimeout(() => this.$refs.searchInput.focus(), 100)
        },
        compareSearch(str, search = this.search) {
            let q = search.toLowerCase()
            str = (str || '').replaceAll('-', '').replaceAll(' ', '').toLowerCase()
            return str.includes(q) || str.includes(q.replaceAll('-', '').replaceAll(' ', ''))
        },
        addSearchedResources(resources, categoryName, mapper) {
            if (resources && resources.length > 0) {
                this.entries.push(categoryName)
                for (const resource of resources) {
                    this.entries.push(mapper(resource))
                }
            }
        }
    }
}
</script>