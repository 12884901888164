<template>
    <div>
        <h1 v-animate-css="{classes: 'fadeIn', delay: 50}">{{ _('project.create.title') }}</h1>

        <div v-animate-css="{classes: 'fadeIn', delay: 125}" class="illustration-modal-vertical-middle">
            <label for="nameInput">{{ _('name') }}</label>
            <b-input autofocus id="nameInput" ref="input" type="text" v-model="title" />
            <b-button class="mt-3 float-right" type="submit">{{ _('project.create.action') }}</b-button>
        </div>
    </div>
</template>
<script>
import { checkProject } from '@/main'

export default {
    name: 'CreateProjectModal',
    data: () => ({
        title: ''
    }),
    methods: {
        async create() {
            const { data } = await this.authApi.createProject({
                title: this.title
            })
            localStorage['current_project'] = data.id

            await checkProject()

            this.$emit('close')
            await this.$router.push({ name: 'project', params: { id: this.$store.state.currentProject.id } })
            this.projectMenuOpened = false
        }
    }
}
</script>
