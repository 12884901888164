<template>
    <div>
        <SidebarProductEntry
            @init="init"
            @add="$router.push({name: 'create-server'})"
            @loadEntries="loadEntries"
            ref="entry"
            @urlChange="s => $emit('urlChange', s)"
        >
            <template v-slot:[`row.context_menu`]="{entry, menu}">
                <server-context-menu v-if="entry" :entry="{id: entry.url.params.id, name: entry.name}" :menu="menu" @deleted="menu.close()" />
            </template>
        </SidebarProductEntry>
    </div>
</template>
<script>
import SidebarProductEntry from '@/components/navigation/sidebar/SidebarProductEntry.vue'
import ServerContextMenu from '@/components/contextmenus/ServerContextMenu.vue'

export default {
    components: { ServerContextMenu, SidebarProductEntry },
    data: () => ({
        domainsCategory: {},
        domainHandlesCategory: {}
    }),
    mounted() {
        this.$refs.entry.init();
    },
    watch: {
        '$route'() {
            for (let entry of this.$refs.entry.serverCategory.entries) {
                entry.url = this.getServerUrl(entry.server)
            }
        }
    },
    methods: {
        init() {
            this.$refs.entry.category = 'servers'
            this.$refs.entry.name = this._('servers.plural')
            this.$refs.entry.page = 1
            this.$refs.entry.total = 0
            this.$refs.entry.categories = []
            this.serverCategory = {}
        },
        async loadEntries(fresh = false, search = '') {

            if (this.$refs.entry.category == 'servers') {
                const serversRes = (await this.computeApi.getServers({
                    search: search,
                    page: this.$refs.entry.page,
                    page_size: 35,
                    'filter[project_id]': this.$store.state.currentProject.id
                }))

                if (fresh)
                    this.init()

                if (serversRes.pagination)
                    this.$refs.entry.total = serversRes.pagination.total

                const servers = serversRes.data

                this.$refs.entry.checkPagination(serversRes.pagination)

                if (fresh) {
                    this.categories = []
                    this.$refs.entry.serverCategory = {
                        name: '',
                        entries: []
                    }
                    this.$refs.entry.categories.push(this.$refs.entry.serverCategory)
                }

                servers.forEach(server => {
                    this.$refs.entry.serverCategory.entries.push({
                        icon: { ONLINE: 'status-online', OFFLINE: 'status-offline' }[server.state] || 'status-unknown',
                        server,
                        subIcon: server.state == 'INSTALLATION' ? 'download-alt' : '',
                        name: server.name,
                        url: this.getServerUrl(server)
                    })
                })
            }
        },
        getServerUrl(server) {
            let url = {
                name: 'server-overview',
                params: {
                    id: server.id
                }
            }

            if (this.$route.meta.singleServerPage) {
                url.name = this.$route.name
            }
            return url
        }
    }
}
</script>
<style lang="scss" scoped>
#create-server-hint {
    p {
        text-align: center;
        margin-top: 40px;
    }
    img {
        margin: 10px auto auto;
        display: block;
        width: 130px;
    }
}
</style>
