import { i18n } from '@/main'

const meta = {
    category: "servers",
    noAutoTitle: true
}

const singleServerMeta = {
    ...meta,
    singleServerPage: true
}

export default [
    {
        path: '/servers',
        name: 'servers',
        meta: {
            category: "servers",
            noAutoTitle: true
        },
        component: () => import('@/views/resources/servers/ServersOverview.vue')
    },
    {
        path: '/servers/create',
        name: 'create-server',
        meta: {
            category: "servers",
            pageTitle: () => i18n.get('servers.create._')
        },
        component: () => import('@/views/resources/servers/CreateServer')
    },

    {
        path: '/servers/:id',
        name: 'server',
        meta: {
            noAutoTitle: true
        },
        component: () => import('@/views/resources/servers/server/Server.vue'),
        children: [
            {
                name: "server-overview",
                path: "",
                component: () => import('@/views/resources/servers/server/Overview.vue'),
                meta: singleServerMeta
            },
            {
                name: "server-charts",
                path: "charts",
                component: () => import('@/views/resources/servers/server/Charts.vue'),
                meta: singleServerMeta
            },
            {
                name: "server-backups",
                path: "backups",
                component: () => import('@/views/resources/servers/server/Backups.vue'),
                meta: singleServerMeta
            },
            {
                name: "server-actions",
                path: "actions",
                component: () => import('@/views/resources/servers/server/Actions.vue'),
                meta: singleServerMeta
            },
            {
                name: "server-settings",
                path: "settings",
                component: () => import('@/views/resources/servers/server/Settings.vue'),
                meta: singleServerMeta
            }
        ]
    }
]
