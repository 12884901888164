<template>
    <portal to="context-menus">
        <div ref="contextMenu" class="context-menu" @click.stop.prevent @contextmenu.stop.prevent v-show="opened"
             :style="style">
            <slot/>
        </div>
    </portal>
</template>
<script>

const contextMenus = []

export default {
    data: () => ({
        opened: false,
        style: { top: '0px', left: '0px' },
        indexOfContextMenus: 0,
        globalClickEvent: () => {
        }
    }),
    created() {
        this.indexOfContextMenus = contextMenus.push(this) - 1

        this.globalClickEvent = e => {
            e.stopPropagation()
            e.preventDefault()
            this.close()
        }
    },
    beforeDestroy() {
        contextMenus.splice(this.indexOfContextMenus, 1)
    },
    methods: {
        open(e) {
            if (this.opened) {
                this.close()
            } else {
                contextMenus.filter(cM => cM != this).filter(cM => cM.opened).forEach(cM => cM.close())
                this.opened = true

                e.stopPropagation()
                e.preventDefault()
                this.style.opacity = 0

                setTimeout(() => {
                    if (this.$refs.contextMenu) {
                        const elWidth = this.$refs.contextMenu.clientWidth
                        const elHeight = this.$refs.contextMenu.clientHeight

                        const leftOffset = e.pageX > window.innerWidth - elWidth ? elWidth : 0
                        this.style.left = `${e.pageX - leftOffset}px`

                        const topOffset = e.pageY - window.scrollY > window.innerHeight - elHeight ? elHeight : 0
                        this.style.top = `${e.pageY - topOffset}px`

                        window.addEventListener('click', this.globalClickEvent)
                        window.addEventListener('contextmenu', this.globalClickEvent)
                        this.style.opacity = 1
                    }
                }, 100);
            }
        },
        close() {
            this.opened = false
            window.removeEventListener('click', this.globalClickEvent)
            window.removeEventListener('contextmenu', this.globalClickEvent)
        }
    }
}
</script>
