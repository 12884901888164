<template>
    <div>
        <SidebarProductEntry
            @init="init"
            @add="$router.push({name: 'create-domain'})"
            @loadEntries="loadEntries"
            ref="entry"
            @urlChange="s => $emit('urlChange', s)"
        />
    </div>
</template>
<script>
import SidebarProductEntry from '@/components/navigation/sidebar/SidebarProductEntry.vue'

export default {
    components: { SidebarProductEntry },
    data: () => ({
        domainsCategory: {}
    }),
    mounted() {
        this.$refs.entry.init();
    },
    methods: {
        init() {
            this.$refs.entry.category = 'domains'
            this.$refs.entry.name = this._('domains.plural')
            this.$refs.entry.page = 1
            this.$refs.entry.categories = []
            this.domainsCategory = {}
        },
        async loadEntries(fresh = false, search = '') {
            if (fresh)
                this.init()

            const domainsReq = (await this.domainApi.getDomains({
                search: search,
                page_size: 10,
                order_by: 'name',
                // page: this.page,
                'filter[project_id]': this.$store.state.currentProject.id
            }))
            const domains = domainsReq.data

            // Adds loadmore button if there are more entries than shown
            this.$refs.entry.checkPagination(domainsReq.pagination)

            if (fresh) {
                this.$refs.entry.categories = []
                this.domainsCategory = {
                    name: '',
                    icon: 'at',
                    entries: []
                }
                this.$refs.entry.categories.push(this.domainsCategory)
            }

            domains.forEach(domain => {
                this.domainsCategory.entries.push({
                    badge: domain.name.split(".")[1],
                    name: domain.name,
                    url: {
                        name: 'domain',
                        params: {
                            id: domain.name
                        }
                    }
                })
            })
        }
    }
}
</script>
