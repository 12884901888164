<template>
    <span class="date" :title="formattedDate">
        {{ relativeTimeformat(date) }}
    </span>
</template>

<script>
export default {
    name: 'DateText',
    props: {
        date: {
            type: String,
        }
    },
    computed: {
        formattedDate() {
            return new Date(this.date).toLocaleString()
        }
    }
}
</script>