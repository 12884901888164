<template>
    <svg width="174" height="174" viewBox="0 0 174 174" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M95.9532 48.2283L25.2755 20.5443L23.4141 25.2966L94.0917 52.9807L95.9532 48.2283Z"
              fill="#C62828"/>
        <path d="M49.5522 120.049L20.9084 19.6932L16.0005 21.0941L44.6443 121.449L49.5522 120.049Z"
              fill="#C62828"/>
        <path d="M55.7745 114.031L99.6909 58.9393L95.6998 55.7579L51.7835 110.85L55.7745 114.031Z"
              fill="#C62828"/>
        <path d="M18.6621 31.4205L128.43 141.189L132.039 137.58L22.2711 27.8115L18.6621 31.4205Z"
              fill="#C62828"/>
        <path d="M133.813 138.153L105.024 60.6174L100.239 62.394L129.029 139.93L133.813 138.153Z"
              fill="#C62828"/>
        <path d="M146.135 134.116L164.475 32.0804L159.812 31.2422L141.471 133.278L146.135 134.116Z"
              fill="#C62828"/>
        <path d="M106.989 49.6815L157.973 27.099L155.906 22.4324L104.922 45.0149L106.989 49.6815Z"
              fill="#C62828"/>
        <path style="animation: logoDot 4s infinite; animation-delay: 1s;"
              d="M20.1844 40.3689C31.332 40.3689 40.3689 31.332 40.3689 20.1844C40.3689 9.03688 31.332 0 20.1844 0C9.03688 0 0 9.03688 0 20.1844C0 31.332 9.03688 40.3689 20.1844 40.3689Z"
              fill="#C62828"/>
        <path style="animation: logoDot 8s infinite; animation-delay: 2s;"
              d="M160.57 36.7672C167.506 36.7672 173.128 31.145 173.128 24.2097C173.128 17.2744 167.506 11.6522 160.57 11.6522C153.635 11.6522 148.013 17.2744 148.013 24.2097C148.013 31.145 153.635 36.7672 160.57 36.7672Z"
              fill="#C62828"/>
        <path style="animation: logoDot 2s infinite; animation-delay: 3s;"
              d="M100.787 68.3728C109.509 68.3728 116.58 61.302 116.58 52.5797C116.58 43.8573 109.509 36.7865 100.787 36.7865C92.0646 36.7865 84.9937 43.8573 84.9937 52.5797C84.9937 61.302 92.0646 68.3728 100.787 68.3728Z"
              fill="#C62828"/>
        <path style="animation: logoDot 3s infinite; animation-delay: 4s;"
              d="M47.7259 139.5C56.9269 139.5 64.3858 132.041 64.3858 122.84C64.3858 113.639 56.9269 106.18 47.7259 106.18C38.5249 106.18 31.066 113.639 31.066 122.84C31.066 132.041 38.5249 139.5 47.7259 139.5Z"
              fill="#C62828"/>
        <path style="animation: logoDot 7s infinite; animation-delay: 5s;"
              d="M139.327 173.59C151.889 173.59 162.073 163.406 162.073 150.844C162.073 138.282 151.889 128.098 139.327 128.098C126.765 128.098 116.581 138.282 116.581 150.844C116.581 163.406 126.765 173.59 139.327 173.59Z"
              fill="#C62828"/>
    </svg>
</template>
<script>
export default {
    name: 'ls-icon'
}
</script>
