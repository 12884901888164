import * as SentryVue from "@sentry/vue";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from '@sentry/tracing'

export function initSentry(vue, router) {
    SentryVue.init({
        Vue: vue,
        dsn: window.APP_CONFIG.sentry_dsn || "https://89fa4063f4c4407e9bfd684b497e1fc0@sentry.lumaserv.com/8",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: SentryVue.vueRouterInstrumentation(router)
            })
        ],
        logErrors: true,
    })
}

export function setSentryUser(user) {
    Sentry.setUser(user)
}