<template>
    <div class="datatable" :class="{'float-top-buttons': floatTopButtons}">
        <b-button v-if="enableAddButton" class="float-right ml-1 icon-btn add-button" @click="$emit('add-button')"><i class="uil uil-plus" /></b-button>
        <collapsable-search-button type="text" class="float-right collapsable-search-button datatable-search" v-model="search" :placeholder="_('datatables.searchPlaceholder', [resourceName])"  :style="{right: !enableAddButton && !relativeRight ? 0 : ''}" />
        <slot name="extra-buttons" />
        <h1 v-if="!hideName">{{ dataTableTitle || resourceName }}</h1>
        <table class="table" :class="{'layout-fixed': layoutFixed}">
            <thead>
                <tr>
                    <th v-for="(entry, key) of structure" :key="key" @click="changeOrder(key)">
                        {{ entry }}
                        <i v-if="orderBy == key && orderDesc" class="uil uil-arrow-up" />
                        <i v-else-if="orderBy == key" class="uil uil-arrow-down" />
                        <i v-else class="uil uil-arrow-down sort-indicator" />
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(entry, i) of contents" :key="i">
                    <td v-for="(name, key) of structure" :key="key">
                        <slot :name="`row.${key}`" :entry="entry">
                            <span style="vertical-align: middle;">{{ valueTransformers[key] ? valueTransformers[key](entry[key]) : entry[key] }}</span>
                        </slot>
                    </td>

                    <td class="icons align-middle">
                        <slot name="actionButtons" :entry="entry"/>
                        <div class="datatable-buttons">
                            <a v-for="(button, i2) of getButtons(entry)" :key="i2"
                               @click="button.click(entry, button)">
                                <i v-if="button.icon" :class="'icon uil '+button.icon" />
                                <b-button v-else-if="button.text" class="ml-1"
                                          :class="[...(button.classList ? button.classList : [])]">{{ button.text }}
                                </b-button>
                            </a>
                        </div>
                        <div class="datatable-buttons-context-menu">
                            <i class="uil uil-ellipsis-v icon-button" @click="$e => $refs['context-menu-entry'][i].open($e)" style="font-size: 18px" />
                            <ContextMenu ref="context-menu-entry">
                                <a v-for="(button, i2) of getButtons(entry)" :key="i2"
                                   @click="button.click(entry, button)">
                                    <i v-if="button.icon" :class="'icon uil '+button.icon" />
                                    <b-button v-else-if="button.text" class="ml-1" variant="transparent">{{ button.text }}</b-button>
                                </a>
                            </ContextMenu>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-center mt-4 mb-4" v-if="total == 0">
            {{ _('datatables.noEntries', [resourceName]) }}
        </div>
        <!--<p class="datatable-result-text">{{ _('datatables.entriesPerPage', [contents.length, resultsPerPage]) }}</p>-->
        <div class="datatable-page-navigation" v-if="total > 0">
            <i class="uil uil-angle-left-b" @click="page > 1 ? page-- : 0; load()"/>
            <template v-for="i of [3, 2, 1]">
                <span :key="`datatable-pagination-${i}`" v-if="page - i > 0" @click="page = page - i; load()">
                    {{ page - i }}
                </span>
            </template>
            <span class="selected">{{ page }}</span>
            <template v-for="i of [1, 2, 3]">
                <span :key="`datatable-pagination-2-${i}`" v-if="page + i <= pages" @click="page = page + i; load()">
                    {{ page + i }}
                </span>
            </template>
            <i class="uil uil-angle-right-b" @click="page < pages ? page++ : 0; load()"/>
        </div>
    </div>
</template>
<script>
import CollapsableSearchButton from '@/components/CollapsableSearchButton.vue'
import ContextMenu from '@/components/ContextMenu.vue'

export default {
    name: 'DataTable',
    components: { ContextMenu, CollapsableSearchButton },
    data: () => ({
        contents: [],
        page: 1,
        pages: 1,
        total: 0,
        search: '',
        lastSearch: '',
        orderBy: null,
        orderDesc: false
    }),
    props: {
        api: { type: Object },
        resource: { type: String },
        query: { type: Object, default: () => ({}) },
        structure: { type: Object, default: () => ({}) },
        buttons: { type: [Array, Function], default: () => [] },
        resultsPerPage: { type: Number, default: 10 },
        resourceName: { type: String, default: '' },
        dataTableTitle: { type: String, default: '' },
        hideName: { type: Boolean, default: false },
        orderEntries: { type: Function, default: a => a },
        valueTransformers: { type: Object, default: () => ({}) },
        useAuthApi: { type: Boolean, default: false },
        initialOrderBy: { type: String, default: null },
        initialOrderDesc: { type: Boolean, default: false },
        enableAddButton: { type: Boolean, default: false },
        layoutFixed: { type: Boolean, default: false },
        relativeRight: { type: Boolean, default: false },
        floatTopButtons: { type: Boolean, default: false }
    },
    timers: {
        updateSearch: {
            autostart: true,
            time: 250,
            repeat: true,
            callback() {
                if (this.lastSearch != this.search) {
                    this.load()
                    this.lastSearch = this.search
                }
            }
        }
    },
    created() {
        this.orderBy = this.initialOrderBy
        this.orderDesc = this.initialOrderDesc
        this.load()
    },
    watch: {
        query(to, from) {
            // Fixes update spamming
            if (JSON.stringify(to) !== JSON.stringify(from)) {
                this.load()
            }
        }
    },
    methods: {
        async load() {
            let { pagination, data } = await this.api.request('get', this.resource, {
                ...this.query,
                page: this.page,
                page_size: this.resultsPerPage,
                search: this.search,
                ...(this.orderBy ? { order_by: this.orderBy, order: this.orderDesc ? 'DESC' : 'ASC' } : {})
            }, null)
            if (data[0] && Object.keys(this.structure).length == 0) {
                for (const key in data[0])
                    this.structure[key] = key
            }
            if (pagination) {
                this.total = pagination.total
                this.pages = Math.ceil(pagination.total / pagination.page_size)
                this.contents = data
            } else {
                this.contents = []
                this.total = data.length
                this.pages = Math.ceil(this.total / this.resultsPerPage)
                data.splice(0, (this.page - 1) * this.resultsPerPage)
                data = data.filter(e => {
                    for (const k in e) {
                        if (e[k].toString().includes(this.search))
                            return true
                    }
                    return false
                })
                for (let i = 0; i < data.length; i++) {
                    const entry = data[i];
                    if (i >= this.resultsPerPage)
                        break;

                    this.contents.push(entry)

                }
            }
            this.$emit('on-loaded', this.contents)
        },
        changeOrder(entry) {
            if (entry == this.orderBy && this.orderDesc) {
                this.orderDesc = false
                this.orderBy = null
            } else if (entry == this.orderBy) {
                this.orderDesc = true
            } else {
                this.orderBy = entry
            }
            this.load()
        },
        getButtons(entry) {
            return typeof this.buttons === 'function' ? this.buttons(entry) : this.buttons
        }
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';

.datatable {
    h1 {
        font-size: 21px;
        padding-top: 2.5px;
    }

    .datatable-result-text {
        float: right;
        font-weight: 500;
        color: $textDefault;
        font-size: 16px;
        margin-top: 7px;
        user-select: none;
    }

    &.modal-datatable {
        h1 {
            margin-top: 16px;
        }
        .collapsable-search-button {
            position: absolute;
            top: 25px;
            right: 58px;
            background: transparent;
            transition: 0.3s;

            i.uil-search-alt {
                color: #424652;
                font-size: 22px;
            }

            svg {
                color: #424652;
                margin: 3.5px 3px;
                width: 24px;
                height: 24px;
            }

            &:hover {
                background: #0000000A;
            }


            &.collapsed {
                background: #0000000A;
                &:hover {
                    border-radius: 6px;
                }
            }
        }
    }
}

.datatable-buttons-context-menu {
    display: none;
}

@media screen and (max-width: 1100px) {
    .datatable-buttons {
        display: none;
    }
    .datatable-buttons-context-menu {
        display: block;
    }
}
</style>
