<template>
    <Modal ref="modal" :m-title="editMode ? _('sshKeys.single') : _('sshKeys.create')" @formSubmit="save">
        <b-form-group :label="_('sshKeys.title')">
            <b-form-input v-model="title"/>
        </b-form-group>
        <b-form-group :label="_('sshKeys.single')">
            <b-form-textarea :readonly="editMode" spellcheck="false" :style="{height: '240px'}" v-model="sshKey" @input="onSSHKeyInput"/>
        </b-form-group>
        <b-form-group v-if="editMode" :label="_('type')">
            <b-input type="text" :value="type" readonly />
        </b-form-group>

        <template v-slot:bottomFixedMenu>

            <template v-if="editMode">
                <b-button variant="danger" @click="deleteKey">{{ _('sshKeys.remove') }}</b-button>
                <b-button type="submit" class="float-right">{{ _('edit') }}</b-button>
            </template>
            <b-button v-else type="submit" class="float-right">{{ _('sshKeys.add') }}</b-button>
        </template>
    </Modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'

export default {
    components: { Modal },
    data: () => ({
        title: '',
        type: '',
        sshKey: ''
    }),
    props: {
        projectId: { type: String },
        // If edit contents is given, it'll automatically go into edit mode instead of creation for a new SSH-Key
        editContents: { type: Object, default: () => null }
    },
    computed: {
        editMode() {
            return !!this.editContents
        }
    },
    methods: {
        onSSHKeyInput() {
            if (this.title === '') {
                const splitBySpace = this.sshKey.split(' ')
                if (splitBySpace.length > 2) {
                    this.title = splitBySpace[2]
                }
            }
        },
        open() {
            this.title = ''
            this.sshKey = ''

            if (this.editMode) {
                this.title = this.editContents.title
                this.sshKey = this.editContents.public_key
                this.type = this.editContents.type
            }

            this.$refs.modal.open()
        },
        close() {
            this.$refs.modal.close()
        },
        async save() {
            let id;
            if (this.editMode) {
                id = this.editContents.id
                await this.computeApi.updateSSHKey(id, {
                    title: this.title
                })
                this.close()
            } else {
                id = (await this.computeApi.createSSHKey({
                    project_id: this.projectId,
                    title: this.title,
                    public_key: this.sshKey
                })).data.id
                this.close()
            }
            this.$emit('saved', { id })
        },
        async deleteKey() {
            await this.computeApi.deleteSSHKey(this.editContents.id)
            this.close()
            this.$emit('deleted')
        }
    }
}
</script>
