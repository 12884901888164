export async function loadWhereIn(resources = [], searchField = 'id', idFieldName = "id", singleEntryRequest = async () => null, singleIdName = "id") {
    let query = {}
    let alreadySearched = []
    let i = 0

    if (resources.length === 0)
        return []

    for (const resource of resources) {
        if (!alreadySearched.includes(resource[idFieldName])) {
            query[`filter[${searchField}][in][${i++}]`] = resource[idFieldName]
            alreadySearched.push(resource[idFieldName])
        }
    }

    const entries = {}
    for (const entry of (await singleEntryRequest(query))) {
        entries[entry[singleIdName]] = entry
    }

    return entries
}