<template>
    <div v-if="project">
        <div id="page-contents">
            <b-container v-animate-css="{classes: 'fadeIn'}">
                <div id="top-info">
                    <h1>{{ project.title }}</h1>
                    <h2>{{ _('project.dashboard.welcome', [$store.state.user.first_name, $store.state.user.last_name]) }}</h2>

                    <div id="project-members">
                        <div id="project-members-profiles" class="hide-mobile">
                            <div
                                @click="openProjectSettingsModal('members')"
                                class="profile-picture"
                                v-for="(member, i) of projectMembers.slice(0, 3)"
                                :key="`member-${i}-${member.id}`"
                                v-animate-css="{classes: 'fadeIn'}"
                            >
                                <span>{{ member ? member.first_name[0] : '?' }}</span>
                            </div>
                        </div>
                        <i @click="$refs.addProjectMemberModal.open()" class="uil uil-user-plus icon-button" />
                        <i @click="openProjectSettingsModal()" class="uil uil-cog icon-button" />

                        <span class="members-count" v-if="projectMembers.length > 3">+{{ projectMembers.length - 3 }}</span>
                    </div>
                </div>
                <b-row>
                    <b-col md="8">
                        <b-container v-if="project">
                            <resources-overview :stats="stats" :key="`servers-${stats.servers},volumes-${stats.volumes},networks-${stats.networks},backups-${stats.backups}`" />

                            <recently-opened />

                            <activities-overview :project-id="project.id" />
                        </b-container>
                    </b-col>

                    <b-col md="4">
                        <b-card id="news-card">
                            <h1>{{ _('home.news') }}</h1>

                            <a class="news-article">
                                <img src="/img/welcome_banner.svg">
                                <h1>Wilkommen im neuen LUMASERV Panel!</h1>
                            </a>
                        </b-card>
                        <b-card class="mt-4 text-center">
                            <a class="social-button" href="https://lumaserv.com" target="_blank"><i :style="{color: '#BC0003'}" class="uil uil-globe" /></a>
                            <a class="social-button" href="https://twitter.com/@LUMASERV" target="_blank"><i :style="{color: '#1DA1F2'}" class="uil uil-twitter-alt" /></a>
                            <a class="social-button" href="https://instagram.com/LUMASERV" target="_blank"><i :style="{color: '#C13584'}" class="uil uil-instagram" /></a>
                            <a class="social-button" href="https://github.com/lumaserv" target="_blank"><i :style="{color: '#24292f'}" class="uil uil-github-alt" /></a>
                            <a class="social-button" href="https://www.linkedin.com/company/lumaserv/" target="_blank"><i :style="{color: '#0072b1'}" class="uil uil-linkedin-alt" /></a>
                            <a class="social-button" href="https://status.lumaserv.com/" target="_blank"><i :style="{color: '#001f2f'}" class="uil uil-chart" /></a>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <ProjectAddMemberModal ref="addProjectMemberModal" :project-id="project.id" />
    </div>
</template>

<script>
import eventBus from '@/eventBus'
import ProjectAddMemberModal from '@/components/projects/ProjectAddMemberModal.vue'
import ResourcesOverview from '@/components/projects/home/ResourcesOverview.vue'
import RecentlyOpened from '@/components/projects/home/RecentlyOpened.vue'
import ActivitiesOverview from '@/components/projects/home/ActivitiesOverview.vue'
import { changeProject, loadWhereIn, setPageTitle } from '@/helper'

export default {
    components: { ActivitiesOverview, RecentlyOpened, ResourcesOverview, ProjectAddMemberModal },
    data: () => ({
        project: null,
        projectMembers: [],
        splicedProjectMembers: [],
        stats: {
            servers: 0,
            volumes: 0,
            networks: 0,
            backups: 0
        }
    }),
    mounted() {
        setPageTitle(this._('home._'))
        this.load(false)
    },
    watch: {
        async '$route.params.id'() {
            this.load()
        }
    },
    methods: {
        async load() {
            this.project = null

            if (localStorage['current_project'] !== this.$route.params.id) {
                await changeProject(this.$route.params.id)
            }

            this.project = (await this.authApi.getProject(this.$route.params.id, {
                detail: true
            })).data

            setPageTitle(this.project.title)

            this.projectMembers = Object.values(await loadWhereIn((await this.authApi.getProjectMembers(this.project.id)).data, 'id', 'user_id', async q => (await this.authApi.getUsers(q)).data))

            this.computeApi.getServers({ 'filter[project_id]': this.project.id, page_size: 0, page: 1 }).then(r => this.stats.servers = r.pagination.total)
            this.computeApi.getNetworks({ 'filter[project_id]': this.project.id, page_size: 0, page: 1 }).then(r => this.stats.networks = r.pagination.total)
            this.computeApi.getServerVolumes({ 'filter[project_id]': this.project.id, page_size: 0, page: 1 }).then(r => this.stats.volumes = r.pagination.total)
            this.computeApi.getServerBackups({ 'filter[project_id]': this.project.id, page_size: 0, page: 1 }).then(r => this.stats.backups = r.pagination.total)
        },
        openProjectSettingsModal(tab) {
            eventBus.$emit('openProjectSettingsModal', tab)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

#top-info {
    margin-bottom: 90px;
    margin-top: 30px;
    border-radius: 7px;
    position: relative;

    h1 {
        margin-bottom: 4px;
        font-size: 32px;
    }
    h2 {
        font-size: 18px;
        opacity: 0.7;
    }

    #project-members {
        position: absolute;
        top: calc(50% - 20px);
        right: 0;
        cursor: pointer;

        .uil-user-plus.icon-button,
        .uil-cog.icon-button {
            margin-top: 2px;
        }

        .uil-user-plus.icon-button {
            margin-left: 0;
        }

        .uil-cog.icon-button {
            margin-left: 3px;
        }

        .members-count {
            position: absolute;
            z-index: 5;
            right: 42px;
            transform: translateX(50%);
            top: 29px;
            border-radius: 20px;
            background: #EEE;
            padding: 0 4px;
            line-height: 16px;
            font-weight: 500;
            font-size: 12px;
        }

        #project-members-profiles {
            .profile-picture {
                position: absolute;
                border: solid 2.5px #FFF;
                border-radius: 10px;
                height: 40px;
                width: 40px;
                transition: 0.3s;
                display: none;

                span {
                    font-size: 23px;
                    font-weight: 600;
                }

                &:nth-child(3) {
                    z-index: 1;
                    right: 110px;
                    background: #fce7cc;
                    display: block;

                    span {
                        color: #faae52;
                    }
                }

                &:nth-child(2) {
                    z-index: 2;
                    right: 95px;
                    background: #cfe1ff;
                    display: block;

                    span {
                        color: #5291fa;
                    }
                }

                &:nth-child(1) {
                    z-index: 3;
                    right: 80px;
                    background: #fccce0;
                    display: block;

                    span {
                        color: #fa4691;
                    }
                }
            }

            &:hover {
                .profile-picture {
                    &:nth-child(3) {
                        right: 120px;
                    }
                    &:nth-child(2) {
                        right: 105px;
                    }
                    &:nth-child(1) {
                        right: 80px;
                    }
                }
            }
        }
    }
}

#page-contents {
    max-width: 1220px;
    padding: 20px;
    margin: 20px auto auto;


    @media screen and (max-width: 720px) {
        padding: 10px 15px;
    }
}

#news-card {
    min-height: 281px;
    .news-article {
        display: block;
        cursor: pointer;
        text-decoration: none;
        color: $textDefault;
        margin: -5px;
        margin-top: 13px;
        padding: 5px;
        margin-bottom: 4px;
        border-bottom: 2px solid #0000000A;

        img {
            width: 100%;
            border-radius: 6px;
        }

        h1 {
            font-size: 20px;
            margin-top: 15px;
        }

        &:hover {
            background: #00000009;
            border-radius: 10px;
            border-bottom-color: transparent;
        }

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }
    }
}

.social-button {
    padding: 8px;
    display: inline-block;
    cursor: pointer;
    border-radius: 100px;
    transition: 0.3s;
    margin: 1px 3px;

    i {
        display: block;
        color: $textDefault;
    }

    &:hover {
        background: #00000011;
    }
}

.product-card {
    @media screen and (max-width: 720px) {
        margin-bottom: 10px;
    }
}
</style>
