<template>
    <div id="app">
        <Navigation v-if="!$route.meta.hideNavigation" />
        <div id="page"
             :class="{'navigation-hidden': $route.meta.hideNavigation, 'sidebar-contents-hidden': $route.meta.hideSidebarContents || $store.state.app.sideNavClosed}">
            <router-view :key="($store.state.currentProject || {id: ''}).id" />
        </div>

        <portal-target name="modals" multiple/>
        <portal-target name="context-menus" multiple/>

        <div id="toasts">
            <div v-for="toast of toasts" :key="toast.id" :style="toast.style" :class="toast.classes">
                <p>{{ toast.text }}</p>
            </div>
        </div>

        <ConfirmationModal
            v-for="(confirmationModal, i) of $store.state.app.confirmationModals"
            :key="`modal-${i}`"
            @confirmed="confirmationModal.confirm()"
            @createdComponent="cm => cm.open()"
            @closed="$store.commit('removeConfirmationModal', i); confirmationModal.reject()"
        >
            {{ confirmationModal.text }}
        </ConfirmationModal>

        <PasswordPrompt
            v-for="(passwordPrompt, i) of $store.state.app.passwordPrompts"
            :key="`password-prompt-${i}`"
            @confirmed="password => passwordPrompt.confirm(password)"
            @createdComponent="cm => cm.open()"
            @rejected="$store.commit('removeConfirmationModal', i); passwordPrompt.reject()"
        >
            {{ passwordPrompt.text }}
        </PasswordPrompt>
    </div>
</template>
<script>
import Navigation from '@/components/navigation/Navigation'
import eventBus from '@/eventBus'
import ConfirmationModal from '@/components/modal/ConfirmationModal'
import PasswordPrompt from '@/components/modal/PasswordPrompt'

export default {
    name: 'App',
    data: () => ({
        toasts: [],
        toastsIncrement: 0
    }),
    created() {
        eventBus.$on('toast', toast => {
            toast = {
                id: `toast-${this.toasts.length}-${++this.toastsIncrement}`,
                timeout: 2000,
                classes: [],
                style: {
                    opacity: 0
                },
                ...toast
            }

            toast.style.opacity = 1
            this.toasts = [...this.toasts, toast]
            setTimeout(() => {
                toast.style.opacity = 0
                setTimeout(() => {
                    this.toasts = this.toasts.filter(t => t.id != toast.id)
                }, 300);
            }, toast.timeout)
        })
        eventBus.$on('success_toast', text => {
            eventBus.$emit('toast', {
                style: {
                    color: '#00FF00'
                },
                text
            })
        })
        eventBus.$on('error_toast', text => {
            eventBus.$emit('toast', {
                classes: ['error'],
                text
            })
        })
    },
    watch: {
        '$route.path'() {
            window.scrollTo(0, 0)
        }
    },
    components: {
        PasswordPrompt,
        ConfirmationModal,
        Navigation
    }
}
</script>
<style lang="scss" scoped>
@import './assets/scss/variables';

#app {
    height: 100%;
}


</style>
