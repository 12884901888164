export default [
    {
        path: '/networks/:id',
        name: 'network',
        meta: {
            category: "servers",
            noAutoTitle: true
        },
        component: () => import('@/views/resources/networks/Network')
    }
]
