<template>
    <b-row class="mt-4 mb-4">
        <b-card class="w-100">
            <DataTable
                ref="activitiesDatatable"
                :api="authApi"
                resource="/audit-log"
                :resource-name="_('home.auditLogs._')"
                :results-per-page="10"
                :query="{'filter[project_id]': projectId}"
                :structure="{action: _('home.auditLogs.action'), date: _('datatables.startedAt')}"
                initial-order-by="started_at"
                initial-order-desc
                :value-transformers="{type: t => _(`servers.actions_types.${t}`), started_at: relativeTimeformat}"
            >
                <template v-slot:[`row.created_at`]="{entry}">
                    <DateText :date="entry.started_at" />
                </template>
            </DataTable>
        </b-card>
    </b-row>
</template>

<script>
import DataTable from '@/components/datatables/DataTable.vue';
import DateText from '@/components/DateText.vue'
import { relativeTimeformat } from '@/helper'

export default {
    components: { DateText, DataTable },
    methods: { relativeTimeformat },
    props: {
        projectId: {
            type: String
        }
    },
    watch: {
        projectId() {
            this.$refs.activitiesDatatable.load()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

.limit-progress {
    margin: 5px 0;
    color: $textDefault;
    h1 {
        font-size: 19px;
    }
    & > div {
        padding: 5px 0;

        i {
            vertical-align: middle;
            display: inline-block;
            font-size: 26px;
            width: 50px
        }

        .small-progress {
            vertical-align: middle;
            width: calc(100% - 100px);
        }
        span {
            display: inline-block;
            width: 50px;
            font-size: 13px;
            text-align: right;
            font-weight: 500;
            opacity: 0.7;
        }
    }
}
</style>
