<template>
    <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="14" y="9" width="41" height="60" rx="5" :stroke="collapsed ? '#AAA' : '#656565'" stroke-width="6"/>
        <rect x="14" y="9" width="13" height="60" rx="5" stroke="#656565" stroke-width="6"/>
    </svg>
</template>
<script>
export default {
    props: {
        collapsed: {
            type: Boolean
        }
    }
}
</script>
