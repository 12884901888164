import { i18n } from '@/main'

export function secondsToFormatted(seconds) {
    const years = Math.floor(seconds / 31536000)
    const days = Math.floor((seconds % 31536000) / 86400)
    const hours = Math.floor(((seconds % 31536000) % 86400) / 3600)
    const minutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
    // seconds = (((seconds % 31536000) % 86400) % 3600) % 60
    const time = (
        (years > 0 ? (`${years}${i18n.get('time.yearsShort')} `) : '') +
        (days > 0 ? (`${days}${i18n.get('time.daysShort')} `) : '') +
        (hours > 0 ? (`${hours}${i18n.get('time.hoursShort')} `) : '') +
        (minutes > 0 ? (`${minutes}${years === 0 && days === 0 && hours === 0 ? i18n.get('time.minutesLongShort') : i18n.get('time.minutesShort')} `) : '')
        // (seconds > 0 ? (`${seconds}${i18n.get('time.secondsShort')}`) : '')
    ).trim()
    return time || `<1${i18n.get('time.minutesShort')}`
}

export function utcToLocalTime(date) {
    if (typeof date === 'string') {
        date = new Date(date.replaceAll(' ', 'T'))
    }

    return new Date(date.getTime() + (new Date().getTimezoneOffset() * 60000))
}

export function relativeTimeformat(ts) {
    const date = utcToLocalTime(ts)

    let seconds = (new Date().getTime() - date.getTime()) / 1000


    const years = Math.floor(seconds / 31536000)
    const days = Math.floor((seconds % 31536000) / 86400)
    const hours = Math.floor(((seconds % 31536000) % 86400) / 3600)
    const minutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
    seconds = (((seconds % 31536000) % 86400) % 3600) % 60

    return i18n.get('time.ago', [(
        years > 0 ? `${years} ${years === 1 ? i18n.get('time.year') : i18n.get('time.passedYears')}` :
            days > 0 ? `${days} ${days === 1 ? i18n.get('time.day') : i18n.get('time.passedDays')}` :
                hours > 0 ? `${hours} ${hours === 1 ? i18n.get('time.hour') : i18n.get('time.passedHours')}` :
                    minutes > 0 ? (minutes + (years === 0 && days === 0 && hours === 0 ? i18n.get('time.minutesLongShort') : i18n.get('time.minutesShort'))) :
                        seconds > 0 ? (seconds + i18n.get('time.secondsShort')) : ' ' + i18n.get('time.lately')
    ).trim()])
}

export function localCurrency(amount) {
    return amount.toFixed(2).replace(".", ",") + "€"
}

export function formatTimestamp(ts) {
    const date = utcToLocalTime(ts)
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
}


export function formatBytesPerSecond(bytes) {
    if (bytes > 1000 ** 4) {
        return `${(bytes / (1000 ** 4)).toFixed(2)} TB/s`
    } else if (bytes > 1000 ** 3) {
        return `${(bytes / (1000 ** 3)).toFixed(2)} GB/s`
    } else if (bytes > 1000 ** 2) {
        return `${(bytes / (1000 ** 2)).toFixed(2)} MB/s`
    } else if (bytes > 1000) {
        return `${(bytes / 1000).toFixed(2)} KB/s`
    }
    return `${bytes} Byte/s`
}