import { i18n } from "@/main";
import store from "@/store";

export function confirmationPrompt(text = i18n.get("confirmationQuestion")) {
    return new Promise((confirm, reject) => {
        store.commit('addConfirmationModal', {
            text,
            confirm,
            reject
        })
    })
}

export function passwordPrompt(text = i18n.get('user.passwordPromptText')) {
    return new Promise((confirm, reject) => {
        store.commit('addPasswordPrompt', {
            text,
            confirm,
            reject
        })
    })
}

export async function askForPassword(entered) {
    // eslint-disable-next-line no-constant-condition
    while (true) {
        let password
        try {
            password = await passwordPrompt()
        } catch (e) {
            break;
        }
        try {
            await entered(password)
            break;
        } catch (e) {
            // Loop into password prompt again
        }
    }
}