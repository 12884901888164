import Home from "@/views/projects/ProjectHome.vue";
import { i18n } from '@/main'

export default [

    {
        path: '/',
        alias: '/projects',
        name: 'select-project',
        meta: {
            loginNotRequired: true,
            hideNavigation: true,
            pageTitle: () => i18n.get('project.selectProject')
        },
        component: () => import('@/views/projects/SelectProject.vue')
    },

    {
        path: '/projects/new',
        name: 'new-project',
        meta: {
            hideNavigation: true,
            pageTitle: () => i18n.get('project.create.title')
        },
        component: () => import('@/views/projects/NewProject.vue')
    },

    {
        path: '/projects/:id',
        name: 'project',
        meta: {
            hideSidebarContents: true,
            noAutoTitle: true,
            category: "home"
        },
        component: Home
    },

]
