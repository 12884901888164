<template>
    <Modal ref="modal" m-width="550px" @closed="$emit('closed')" @opened="$emit('opened')" @formSubmit="$emit('formSubmit', $event)" no-content-padding>
        <div id="vert-illustration-modal">
            <div id="modal-contents">
                <slot name="modal" />
            </div>
            <div id="illustration">
                <slot name="illustration" />
            </div>
        </div>
    </Modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'
export default {
    components: { Modal },
    methods: {
        open() {
            this.$refs.modal.open()
        },
        close() {
            this.$refs.modal.close()
        },
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables";
#vert-illustration-modal {
    #modal-contents {
        padding: 25px;
        display: inline-block;
        width: 62%;
        vertical-align: top;
        transition: 0.3s opacity;
    }

    #illustration {
        padding: 20px;
        width: 38%;
        display: inline-block;
        height: 310px;
        background: #BC00030D;
        vertical-align: middle;
        position: relative;
        overflow: hidden;

        img, svg {
            width: calc(100% - 40px);
        }

        #loading {
            width: 70%;
        }

        #loading, img, svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    @media screen and (max-width: 720px) {
        #modal-contents {
            display: block;
            width: 100%;
        }

        #illustration {
            display: none;
        }
    }
}

.illustration-modal-vertical-middle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 265px);

    @media screen and (max-width: 720px) {
        position: initial;
        width: 100%;
        top: initial;
        transform: none;
    }
}
</style>
