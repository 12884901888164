import Vue from 'vue'
import VueRouter from 'vue-router'
// import domainRoutes from '@/router/domainRoutes'
import pleskRoutes from '@/router/pleskRoutes'
import serverRoutes from '@/router/serverRoutes'
import firewallRoutes from '@/router/firewallRoutes'
import volumeRoutes from '@/router/volumeRoutes'
import networkRoutes from '@/router/networkRoutes'
import store from '@/store'
import { setPageTitle } from '@/helper'
import projectRoutes from "@/router/projectRoutes";
import { i18n } from '@/main'

Vue.use(VueRouter)

const routes = [

    // ...domainRoutes,
    ...pleskRoutes,
    ...serverRoutes,
    ...firewallRoutes,
    ...volumeRoutes,
    ...networkRoutes,

    {
        path: '/user/my-account',
        name: 'my-account',
        meta: {
            hideSidebarContents: true,
            noAutoTitle: true
        },
        component: () => import('@/views/user/MyAccount')
    },

    {
        path: '/pricings',
        name: 'pricings',
        meta: {
            hideSidebarContents: true,
            pageTitle: () => i18n.get('pricings._')
        },
        component: () => import('@/views/user/PricingsOverview')
    },

    ...projectRoutes,

    {
        path: '/login',
        name: 'login',
        meta: {
            loginNotRequired: true,
            hideNavigation: true,
            pageTitle: () => i18n.get('auth.login.title')
        },
        component: () => import('@/views/user/Login')
    },


    {
        path: '/*',
        name: 'not-found',
        meta: {
            hideSidebarContents: true,
            pageTitle: () => i18n.get('pageNotFound')
        },
        component: () => import('@/views/NotFound')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.getRoutes()
    .filter(r => !r.meta?.noAutoTitle && !r.meta?.pageTitle && r.name)
    .forEach(r => {
        throw new Error(`Please set either a pageTitle to a title or add noAutoTitle and set it manually for route ${r.name}`)
    })

router.beforeEach((to, from, next) => {
    if (to.meta?.pageTitle) {
        setPageTitle(typeof to.meta.pageTitle === 'string' ? to.meta.pageTitle : to.meta.pageTitle())
    } else if (!to.meta?.noAutoTitle) {
        setPageTitle(to.name)
    }
    window.scrollTo(0, 0)
    store.state.routerHistory.push(from)
    next()
})

export default router
