import store from '@/store'

const recentlyOpenedList = {
    get() {
        return JSON.parse(localStorage.getItem(`recently-opened-${store.state.currentProject.id}`) || '[]').slice(0, 4)
    },
    set(list) {
        localStorage.setItem(`recently-opened-${store.state.currentProject.id}`, JSON.stringify(list))
    },
    add(id, name, type, url) {
        recentlyOpenedList.set([
            { id, name, type, url },
            ...recentlyOpenedList.get().filter(e => !(e.id === id && e.type === type))
        ])
    }
}

export default recentlyOpenedList
