export function setTheme(vars = {}) {
    for (const key in vars) {
        document.documentElement.style.setProperty("--" + key, vars[key])
    }
}

export function setLightTheme() {
    setTheme({
        primary: "#BC0003",
        primaryAlpha: "#BC000310",
        primaryHoverAlpha: "#BC000322",
        defaultBackground: "#FFFFFF",
        pageBackground: "#FFFFFF",
        textDefault: "#626778",
        highlightedText: "#424652",
        highlightedTextAlpha: "#42465215",
        labelColor: "#878787",
        iconsColor: "#656565",
        borderColor: "#EEEEEE"
    })
}

export function setDarkTheme() {
    setTheme({
        primary: "#BC0003",
        primaryAlpha: "#BC000321",
        primaryHoverAlpha: "#BC000322",
        defaultBackground: "#23232D",
        pageBackground: "#2A2A35",
        textDefault: "#ffffffDD",
        highlightedText: "#ffffff",
        highlightedTextAlpha: "#ffffff15",
        labelColor: "#FFFFFF99",
        iconsColor: "#FFFFFF",
        borderColor: "#424256"
    })
}