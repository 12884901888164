import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'

import { setDarkTheme, setLightTheme } from "@/helper";
import '@/helper/hiringConsoleLog'
import '@/registerServiceWorker'
import { authApi, billingApi, computeApi, domainApi } from '@/api'
import I18n from '@/i18n'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@popperjs/core'
import '@/assets/scss/app.scss'


import VueObserveVisibility from 'vue-observe-visibility'
import VueTimers from 'vue-timers'
import VAnimateCss from 'v-animate-css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import PortalVue from 'portal-vue'
import VueApexCharts from 'vue-apexcharts'
import { initSentry } from '@/helper/sentryInit'

Vue.use(VueApexCharts)
Vue.use(PortalVue)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VAnimateCss)
Vue.use(VueObserveVisibility)
Vue.use(VueTimers)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

export const i18n = new I18n()

Vue.mixin({
    data: () => ({
        authApi,
        billingApi,
        computeApi,
        domainApi,
        app: {
            config: window.APP_CONFIG
        }
    }),
    created() {
        if (this.$options.contextBasedSearch) {
            store.state.contextBasedSearchEntries[this._uid.toString()] = this.$options.contextBasedSearch.bind(this)
        }
    },
    beforeDestroy() {
        if (this.$options.contextBasedSearch) {
            delete store.state.contextBasedSearchEntries[this._uid.toString()]
        }
    },
    methods: {
        _(name, values = {}) {
            return i18n.get(name, values)
        }
    }
})

export async function checkProject() {
    let project;

    await store.dispatch("loadProjects")
    const projects = store.state.projects

    if (localStorage["current_project"]) {
        try {
            project = (await authApi.getProject(localStorage["current_project"])).data
            store.commit("setCurrentProject", project)
        } catch (e) {
            localStorage.removeItem("current_project")
            return await checkProject()
        }

        try {
            const priceRange = (await computeApi.getServerPriceRangeAssignments()).data[0]
            store.commit("setServerPriceRange", priceRange || null)
        } catch (e) {
            // If no price range assignments are available just do nothing
        }
    } else {
        if (projects.length > 0) {
            localStorage["current_project"] = projects[0].id
            return await checkProject()
        } else {
            await router.push({ name: 'new-project' })
        }
    }

    return project
}

export async function checkUser() {
    if (localStorage["auth"]) {
        const auth = JSON.parse(localStorage["auth"]);

        const apiClients = [computeApi, domainApi, authApi, billingApi]
        for (const api of apiClients) {
            api.setToken(auth.token);
        }
    }

    try {
        const user = (await authApi.validateSelf()).data.user
        user.loggedIn = true
        store.commit('setUser', user)
        await checkProject()
    } catch (e) {
        //
    }
}

initSentry(Vue, router);

(async () => {
    try {
        if (localStorage["lang"] === 'EN') {
            i18n.useLanguage((await import('./assets/data/lang/en.js')).default)
        } else {
            i18n.useLanguage((await import('./assets/data/lang/de.js')).default)
        }
    } catch (e) {
        console.error('Could not load language files')
    }

    try {
        await checkUser()
    } catch (e) {
        //
    }

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')

    // Trigger the guard
    await checkUser()
})()

const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
const darkmodeListener = e => {
    if (e.matches) {
        setDarkTheme()
    } else {
        setLightTheme()
    }
}

export function setTheme(name) {
    darkThemeMq.removeListener(darkmodeListener)

    if (name === 'dark') {
        setDarkTheme()
    } else if (name === 'auto') {
        darkThemeMq.addListener(darkmodeListener)
        darkmodeListener(window.matchMedia("(prefers-color-scheme: dark)"))
    } else {
        setLightTheme()
    }
}

setTheme(localStorage["theme"])