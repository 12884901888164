import Vue from 'vue'
import Vuex from 'vuex'
import { authApi } from '@/api'
import { setSentryUser } from '@/helper/sentryInit'

Vue.use(Vuex)

const state = new Vuex.Store({
    state: {
        user: {
            "loggedIn": false,
            "id": 0,
            "email": "user@example.com",
            "first_name": "string",
            "last_name": "string",
            "gender": "MALE",
            "street": "string",
            "street_number": "string",
            "additional_address": "string",
            "city": "string",
            "postal_code": "string",
            "state": "PENDING"
        },
        projects: [],
        currentProject: {},
        app: {
            sideNavClosed: false,
            confirmationModals: [],
            passwordPrompts: []
        },
        contextBasedSearchEntries: {},

        serverPriceRange: null,

        routerHistory: []
    },
    mutations: {
        addConfirmationModal(state, modal) {
            state.app.confirmationModals = [...state.app.confirmationModals, modal]
        },
        removeConfirmationModal(state, index) {
            state.app.confirmationModals = state.app.confirmationModals.filter((_, i) => i !== index)
        },
        addPasswordPrompt(state, modal) {
            state.app.passwordPrompts = [...state.app.passwordPrompts, modal]
        },
        removePasswordPrompt(state, index) {
            state.app.passwordPrompts = state.app.passwordPrompts.filter((_, i) => i !== index)
        },
        setUser(state, user) {
            state.user = user
            setSentryUser({
                ...user,
                selected_project: state.currentProject
            })
        },
        setProjects(state, projects) {
            state.projects = projects
        },
        toggleSideNavClosed() {
            state.commit('setSideNavClosed', !state.state.app.sideNavClosed)
        },
        setSideNavClosed(state, value) {
            state.app.sideNavClosed = value
            localStorage.setItem('isSideBarClosed', value ? 'true' : 'false')
        },
        setCurrentProject(state, project) {
            state.currentProject = project
        },
        setServerPriceRange(state, priceRange) {
            state.serverPriceRange = priceRange
        }
    },
    actions: {
        async loadProjects(context) {
            context.commit("setProjects", (await authApi.getProjects()).data)
        }
    },
    modules: {}
})


if (localStorage.getItem('isSideBarClosed') !== undefined) {
    state.commit('setSideNavClosed', localStorage.getItem('isSideBarClosed') === 'true')
} else if (window.innerWidth >= 1000) {
    state.commit('toggleSideNavClosed')
}

export default state
