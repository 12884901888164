<template>
    <div>
        <VerticalIllustrationModal ref="modal" @formSubmit="addUser">
            <template v-slot:modal>
                <h1>{{ _('project.addMember') }}</h1>
                <div class="illustration-modal-vertical-middle">
                    <label for="mailInput">{{ _('user.email') }}</label>
                    <b-input autofocus id="mailInput" v-model="mail" type="email" />
                    <b-button class="float-right mt-3" type="submit">{{ _('project.addMember') }}</b-button>
                </div>
            </template>

            <template v-slot:illustration>
                <img src="/img/illustrations/add_member.svg" />
            </template>
        </VerticalIllustrationModal>
    </div>
</template>
<script>
import VerticalIllustrationModal from '@/components/modal/VerticalIllustrationModal'

export default {
    components: { VerticalIllustrationModal },
    data: () => ({
        mail: ""
    }),
    props: {
        projectId: { type: String }
    },
    mounted() {

    },
    methods: {
        async addUser() {
            await this.authApi.createProjectInvite({
                project_id: this.projectId,
                email: this.mail,
            })
            this.$refs.modal.close()
        },
        open() {
            this.mail = ''
            this.$refs.modal.open()
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
</style>
