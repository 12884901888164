<template>
    <b-row class="mt-3">
        <b-card style="width: 100%">
            <h1 class="mb-3">{{ _('home.recentlyOpened') }}</h1>
            <div id="recently-opened-app">
                <p v-if="recentlyOpened.length === 0">{{ _('home.nothingRecentlyOpened') }}</p>
                <router-link v-for="entry of recentlyOpened" :key="`${entry.type}-${entry.id}`" :to="entry.url">
                    <i v-if="resourceIcons[entry.type]" :class="`uil uil-${resourceIcons[entry.type]}`" />
                    <h1>{{ entry.name }}</h1>
                </router-link>
            </div>
        </b-card>
    </b-row>
</template>
<script>
import recentlyOpened from '@/helper/recentlyOpened'

export default {
    data: () => ({
        recentlyOpened: [],
        resourceIcons: {
            SERVER: 'servers',
            DOMAIN: 'at'
        }
    }),
    mounted() {
        this.recentlyOpened = recentlyOpened.get()
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

#recently-opened-app {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: 97px;

    a {
        display: inline-block;
        text-align: center;
        margin-right: 13px;
        padding: 10px;
        border-radius: 8px;
        color: $textDefault;
        vertical-align: top;

        i {
            display: block;
            font-size: 36px;
            margin: auto auto 10px;
        }

        h1 {
            font-size: 18px;
            color: $textDefault;
            min-width: 110px;
            max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:hover {
            background: #00000011;
        }
    }

    & > p {
        white-space: pre-wrap;
    }


    @media screen and (max-width: 720px) {
        a {
            i {
                font-size: 27px;
            }

            h1 {
                font-size: 15px;
                min-width: 60px;
                max-width: 80px;
            }
        }
    }
}
</style>
