<template>
    <div>
        <div id="top" :class="{'search-opened': searchOpened}">
            <div id="action-buttons" v-if="category">
                <i class="uil uil-filter" @click="openSearch" :style="{fontSize: '22px', padding: '6px', position: 'relative'}"/>
                <i style="position: relative" class="uil uil-plus" @click="clickCloseOrAdd"/>
            </div>
            <input 
                ref="searchInput" 
                @keyup.enter="$emit('loadEntries', true); lastSearch = search" 
                v-model="search" 
                type="text"
                :placeholder="`${_('search._')}`"
                @focusout="closeOnEmpty"
                id="category-search"
            >
            <h1>{{ name }}</h1>
        </div>

        <div id="entries">
            <!--<div v-if="categories.length > 1">
                <span class="label" style="margin-top: 3px;">
                    <span>{{_('sidebar.overview')}}</span>
                </span>

                <a v-for="(category, i) of categories" :key="i" class="entry" v-show="category.name" :href="'#'+category.name">
                    <i v-if="category.icon" :class="'uil uil-'+category.icon" />
                    <span>{{category.name}}</span>
                </a>
            </div>-->

            <div v-for="(innerCategory, i) of categories" :key="i" :id="innerCategory.name">
                <span class="label" v-if="innerCategory.name && innerCategory.entries.length > 0">
                    <div class="action-buttons" v-if="innerCategory.actionButtons">
                        <i v-for="(actionButton, i2) of innerCategory.actionButtons" :key="i2"
                           :class="'uil uil-'+actionButton.icon" @click="actionButton.click" />
                    </div>
                    <div class="collapse-button"
                         @click="collapsed.includes(innerCategory.name) ? collapsed.splice(collapsed.indexOf(innerCategory.name), 1) : collapsed.push(innerCategory.name)">
                        <i v-if="innerCategory.collapsable" style="float: none; margin: 0; transition: 0.3s"
                           class="uil uil-angle-right-b"
                           :style="{transform: collapsed.includes(innerCategory.name) ? '' : 'rotate(90deg)'}" />
                        <span style="margin-left: 8px;">{{ innerCategory.name.toUpperCase() }}</span>
                    </div>
                </span>

                <template v-for="(entry, i2) of innerCategory.entries">
                    <router-link
                        :key="`sidenav-entry-${i2}`"
                        class="entry"
                        :to="entry.url"
                        @contextmenu.native="$refs.contextMenu[i2].open($event)"
                        v-show="!innerCategory.collapsable || !collapsed.includes(innerCategory.name)"
                        @click.native="$emit('urlChange', {from: 'sidebar-entry'})"
                    >
                        <span v-if="entry.badge" class="s-badge"><b>{{ entry.badge }}</b></span>
                        <template v-if="entry.icon">
                            <div v-if="['status-unknown', 'status-online', 'status-offline'].includes(entry.icon)"
                                 :class="entry.icon">
                                <div/>
                            </div>
                            <i v-else :class="'uil uil-'+entry.icon" />
                        </template>
                        <span>{{ entry.name }}</span>
                    </router-link>
                    <ContextMenu ref="contextMenu" :key="`sidenav-context-menu-${i2}`">
                        <slot v-if="$refs.contextMenu && $refs.contextMenu[i2]" name="row.context_menu" :entry="entry" :menu="$refs.contextMenu[i2]" />
                    </ContextMenu>
                </template>

                <b-button v-if="showMoreButton" v-observe-visibility="visibilityChanged" @click="$emit('loadEntries')"
                          style="display: block">{{ _('sidebar.loadMore') }}
                </b-button>
            </div>

        </div>
        <slot name="test"/>
    </div>
</template>
<script>
import eventBus from '@/eventBus'
import ContextMenu from '@/components/ContextMenu.vue'

export default {
    components: { ContextMenu },
    data: () => ({
        category: "",
        name: "",
        search: "",
        lastSearch: "",
        searchOpened: false,
        categories: [],
        page: 1,
        total: 0,
        showMoreButton: false,
        collapsed: []
    }),
    timers: {
        loadEntriesOnInput: {
            autostart: true,
            time: 500,
            repeat: true,
            callback() {
                if (this.search != this.lastSearch) {
                    this.$emit('loadEntries', true, this.search)
                    this.lastSearch = this.search
                }
            }
        }
    },
    methods: {
        init() {
            this.$emit('init')
            this.$emit('loadEntries', true)

            eventBus.$on("loadSidenavContents", () => {
                this.$emit('loadEntries', true)
            })
        },
        checkPagination(pagination) {
            if (pagination) {
                this.showMoreButton = pagination.page * pagination.page_size < pagination.total
            }
        },
        visibilityChanged(visible) {
            if (visible)
                this.$emit('loadEntries', false)
        },
        openSearch() {
            this.searchOpened = true
            setTimeout(() => this.$refs.searchInput.focus(), 100)
        },
        clickCloseOrAdd() {
            if (this.searchOpened) {
                this.searchOpened = false
                this.search = ''
                this.$emit('loadEntries', true)
            } else {
                this.$emit('add')
            }
        },
        closeOnEmpty() {
            if (this.search === '') {
                this.searchOpened = false
            }
        }
    }
}
</script>
