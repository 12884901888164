export default [
    {
        path: '/licenses/plesk',
        name: 'plesk-licences',
        meta: {
            category: "plesk",
            noAutoTitle: true
        },
        component: () => import('@/views/resources/plesk/PleskLicensesOverview')
    },
    {
        path: '/licenses/plesk/:id',
        name: 'plesk-licence',
        meta: {
            category: "plesk",
            noAutoTitle: true
        },
        component: () => import('@/views/resources/plesk/PleskLicense')
    }
]
