<template>
    <div>
        <TabbedModal ref="modal"
                     :pages="{overview: _('overview._'), members: _('project.members'), sshkeys: _('sshKeys.plural')}"
                     m-title="Projekt">
            <template v-slot:page-overview>
                <h1>{{ _('project.single') }}</h1>
                <label>{{ _('name') }}</label>
                <b-input type="text" v-model="project.title" />

                <b-button class="mt-3 float-right" @click="saveProject">{{ _('save') }}</b-button>
                <br><br><br>
                <label>{{ _('project.id') }}</label>
                <b-input-group>
                    <b-input class="one-click-select" :value="project.id" readonly/>
                    <b-input-group-append>
                        <b-button>
                            <CopyText :text="_('copy')" :copy-text="project.id" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>

                <br>
                <b-button variant="danger" class="mt-2 float-right" @click="confirmationPrompt(_('project.deleteConfirmation', [project.title])).then(deleteProject)">{{ _('project.delete') }}</b-button>
            </template>
            <template v-slot:page-members>
                <members-overview :project="project" />
            </template>
            <template v-slot:page-sshkeys>
                <h1>{{ _('sshKeys.plural') }}</h1>
                <div v-for="(sshKey, i) of sshKeys" :key="i" class="selectable-card"
                     @click="$refs['editSSHKeyModal'][i].open()">
                    <key-icon />
                    <span>{{ sshKey.title }}</span>
                </div>
                <div class="selectable-card add" @click="$refs.createSSHKeyModal.open()">
                    <i class="uil uil-plus" />
                    <span>{{ _('sshKeys.create') }}</span>
                </div>
            </template>
        </TabbedModal>

        <SSHKeyModal @saved="loadSSHKeys" :project-id="project.id" ref="createSSHKeyModal"/>

        <SSHKeyModal
            v-for="(sshKey, i) of sshKeys" :key="`project-ssh-key-${i}`"
            :ref="'editSSHKeyModal'"
            @saved="loadSSHKeys"
            @deleted="loadSSHKeys"
            :project-id="project.id"
            :edit-contents="sshKey"
        />
    </div>
</template>
<script>
import TabbedModal from '@/components/modal/TabbedModal'
import { checkProject } from '@/main'
import SSHKeyModal from '@/components/resources/servers/SSHKeyModal'
import MembersOverview from '@/components/projects/settings/MembersOverview'
import KeyIcon from '@/components/icons/KeyIcon'
import CopyText from "@/components/CopyText";
import { confirmationPrompt } from '@/helper'

export default {
    components: { CopyText, KeyIcon, MembersOverview, TabbedModal, SSHKeyModal },
    data() {
        return {
            projectMembers: [],
            sshKeys: [],
            apiKeys: []
        }
    },
    props: {
        project: { type: Object }
    },
    mounted() {
        this.$refs.modal.$on('tabChanged', async tab => {
            if (tab === 'sshkeys') {
                await this.loadSSHKeys()
            } else if (tab === 'apikeys') {
                await this.loadAPIKeys()
            }

            this.$refs.modal.selectedTab = tab
        })
    },
    methods: {
        confirmationPrompt,
        open(tab = 'overview') {
            this.$refs.modal.open()
            this.$refs.modal.setTab(tab)
        },
        async saveProject() {
            await this.authApi.updateProject(this.project.id, this.project)
            await checkProject()
        },
        async deleteProject() {
            await this.authApi.deleteProject(this.project.id)
            await checkProject()
        },
        async loadSSHKeys() {
            this.sshKeys = (await this.computeApi.getSSHKeys({
                'filter[project_id]': this.project.id
            })).data
        },
        async loadAPIKeys() {
            this.apiKeys = (await this.authApi.getTokens({
                'filter[scope][project_id]': this.project.id
            })).data
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

h1 {
    margin-left: -1px !important;
}


</style>
