<template>
    <div>
        <div id="project-menu" v-if="opened" @click.stop v-animate-css="{classes: 'fadeIn', duration: 200}">
            <div v-if="$store.state.currentProject">
                <router-link id="top" :to="{name: 'project', params: {id: $store.state.currentProject.id}}" @click.native="close">
                    <div class="profile-picture" :style="profilePictureStyling">
                        <span>
                            {{ $store.state.currentProject.title ? $store.state.currentProject.title[0].toUpperCase() : '?' }}
                        </span>
                    </div>
                    <h1>{{ $store.state.currentProject.title ? $store.state.currentProject.title : _('unknown') }}</h1>
                </router-link>
            </div>

            <label>{{ _('project.change') }}</label>
            <a
                class="project navigation-menu-button"
                v-for="(project) of projectsExceptCurrent"
                :key="`project-${project.id}`"
                @click="changeProject(project.id)"
            >
                <div class="profile-picture"
                     :style="{color: generateColorByString(project.title), background: generateColorByString(project.title)+'17'}">
                    <span>
                        {{ (project.title ? project.title : '#')[0].toUpperCase() }}
                    </span>
                </div>
                <span>{{ project.title }}</span>
            </a>

            <a @click="$refs.createProjectModal.open(); close()" class="bottom-floating navigation-menu-button">
                <i class="uil uil-plus" style="font-size: 26px; margin: 2px 18px;" />
                <span>{{ _('project.addProject') }}</span>
            </a>


        </div>
        <CreateProjectModal ref="createProjectModal"/>
    </div>
</template>

<script>
import { changeProject, generateColorByString } from '@/helper'
import CreateProjectModal from "@/components/projects/CreateProjectModal.vue";
import eventBus from "@/eventBus";

export default {
    name: "ProjectMenu",
    components: { CreateProjectModal },
    data: () => ({
        opened: false
    }),
    mounted() {
        eventBus.$on('projectChanged', () => {
            this.close()
        })
    },
    computed: {
        projectsExceptCurrent() {
            return this.$store.state.projects.filter(p => p.id !== this.$store.state.currentProject.id)
        },
        profilePictureStyling() {
            return { color: this.generateColorByString(this.$store.state.currentProject.title), background: `${this.generateColorByString(this.$store.state.currentProject.title)}17` }
        }
    },
    watch: {
        opened(to) {
            this.$emit(to ? 'opened' : 'closed')
        }
    },
    methods: {
        generateColorByString,
        changeProject,
        open() {
            this.opened = true
        },
        close() {
            this.opened = false
        },
        toggle() {
            this.opened = !this.opened
        },
    }
}
</script>