<template>
    <div @click.stop id="user-menu" v-if="opened" v-animate-css="{classes: 'fadeIn', duration: 200}">
        <router-link id="top" :to="{name: 'my-account'}" @click.native="close">
            <div class="profile-picture">
                <span>
                    {{ $store.state.user.first_name ? $store.state.user.first_name[0].toUpperCase() : '?' }}
                </span>
            </div>
            <h1>
                {{ $store.state.user.first_name || 'Unknown' }}
                {{ $store.state.user.last_name  || 'Unknown' }}
            </h1>
        </router-link>
        <a @click.stop="showLanguages = !showLanguages" class="navigation-menu-button">
            <img :src="require(`ia-flag-icons/states/${selectedLang == 'EN' ? 'UK' : (selectedLang ? selectedLang : 'DE')}.svg`)">
            <span>{{ _('language') }}</span>
        </a>
        <div v-show="showLanguages">
            <hr>
            <a v-for="(name, lang) of availableLanguages" :key="lang" @click="changeLang(lang)" class="navigation-menu-button">
                <img :src="require(`ia-flag-icons/states/${lang === 'EN' ? 'UK' : lang}.svg`)">
                <span>{{ name }}</span>
            </a>
            <hr>
        </div>

        <router-link :to="{name: 'my-account'}" @click.native="close" class="navigation-menu-button">
            <i class="uil uil-user" />
            <span>{{ _('user.myAccount') }}</span>
        </router-link>
        <router-link :to="{name: 'my-account', hash: '#api'}" @click.native="close" class="navigation-menu-button">
            <i class="uil uil-brackets-curly" />
            <span>{{ _('user.apiTokens') }}</span>
        </router-link>

        <a @click="logout" class="navigation-menu-button">
            <i class="uil uil-sign-out-alt" />
            <span>{{ _('user.logOut') }}</span>
        </a>
        <br>
    </div>
</template>

<script>
export default {
    name: "UserMenu",
    data: () => ({
        opened: false,
        showLanguages: false,
        selectedLang: localStorage['lang'],
        availableLanguages: {
            DE: 'Deutsch',
            EN: 'English'
        },
    }),
    mounted() {

    },
    watch: {
        opened(to) {
            this.$emit(to ? 'opened' : 'closed')
        }
    },
    methods: {
        logout() {
            localStorage.removeItem('auth')
            window.location = '/login'
        },
        changeLang(lang) {
            localStorage['lang'] = lang
            window.location = ''
        },
        open() {
            this.opened = true
        },
        close() {
            this.opened = false
        },
        toggle() {
            this.opened = !this.opened
        }
    }
}
</script>

<style scoped>

</style>