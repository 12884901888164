<template>
    <span class="copy-text" :class="{copied}" @click="click" @mouseleave="copied = false">
        <span class="copy-text-inner">{{ text }}</span>
        <span class="tooltip">
            {{ copied ? `${_('copied')} ✓` : _('copy') }}
        </span>
    </span>
</template>

<script>
import { copyStringToClipboard } from '@/helper'

export default {
    props: {
        text: { type: String },
        copyText: { type: String, default: '' },
    },
    data: () => ({
        copied: false
    }),
    methods: {
        click() {
            this.copied = true
            copyStringToClipboard(this.copyText || this.text)
            setTimeout(() => {
                this.copied = false
            }, 3000)
        }
    }
}
</script>
<style lang="scss" scoped>
.copy-text {
    position: relative;
    transition: 0.3s;

    .copy-text-inner {
        user-select: text;
        cursor: pointer;
    }

    .tooltip {
        transition-delay: 0s;
        margin-top: -28px;
    }

    &.copied {
        .tooltip {
            color: #39e065;
            white-space: nowrap;
        }
    }
}
</style>
