<template>
    <portal to="modals">
        <div id="modal" v-if="opened || cssHide" @click="close"
             :class="{
                 'hidden': cssHide && !opened,
                 'no-content-padding': noContentPadding,
                 ...childclass
             }" v-animate-css="{classes: 'fadeIn', duration: 200}">
            <form @submit.prevent.stop="$emit('formSubmit')">
                <div id="modal-modal" @click.stop="$emit('emptyClick')" :style="{width: mWidth, minWidth: noMinWidth ? 0 : ''}">

                    <i v-if="showCloseButton" id="close-button" @click="close" class="uil uil-multiply" />
                    <div v-if="mTitle || $slots.header" id="modal-title">
                        <h1 v-if="mTitle">{{ mTitle }}</h1>
                        <slot v-if="$slots.header" name="header" />
                    </div>
                    <div v-if="!disableContent" ref="modalBody" @scroll="scrolled = $event.target.scrollTop > 0"
                         id="modal-body">
                        <slot/>
                    </div>
                    <slot name="belowContent" />

                    <div class="bottom-menu" v-if="$slots.bottomFixedMenu">
                        <slot name="bottomFixedMenu" />
                    </div>
                </div>
            </form>
        </div>
    </portal>
</template>
<script>
let openedModals = []


const closeButtonEvent = e => {
    if (openedModals.length) {
        e.preventDefault()
        e.stopImmediatePropagation()

        openedModals[openedModals.length - 1].close()
    }
}

window.addEventListener('keydown',  e => {
    if (e.key == 'Escape') {
        closeButtonEvent(e)
    }
})
// Android back button event
document.addEventListener('backbutton', closeButtonEvent)

export default {
    name: "Modal",
    data: function () {
        return {
            opened: false,
            onOpen: () => {
            },
            onClose: () => {
            },
            scrolled: false
        }
    },
    created() {
    },
    props: {
        mTitle: { type: String, default: '' },
        showCloseButton: { type: Boolean, default: true },
        cssHide: { type: Boolean, default: false },
        mWidth: { type: [Number, String], default: null },
        noContentPadding: { type: Boolean, default: false },
        childclass: { type: Object, default: () => ({}) },
        disableContent: { type: Boolean, default: false },
        noMinWidth: { type: Boolean, default: false }
    },
    beforeDestroy() {
        this.close()
    },
    methods: {
        close() {
            this.opened = false
            this.onClose()
            this.$emit("closed")
            openedModals = openedModals.filter(m => m._uid != this._uid)
        },
        open() {
            this.opened = true
            this.onOpen()
            this.$emit('opened')
            openedModals.push(this)
        }
    }
}
</script>
<style lang="scss" scoped>

</style>
