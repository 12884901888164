<template>
    <div>
        <Sidebar ref="sidebar" :manually-opened="manuallyOpened" :mobile="mobile"/>
        <div id="navigation">
            <svg v-if="mobile" @click="manuallyOpened = !manuallyOpened" id="open-menu-button" viewBox="0 0 16 16"
                 class="bi bi-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>

            <router-link :to="{name: 'project', params: {id: $store.state.currentProject.id}}" id="logo">
                <ls-icon />
                <span
                    class="beta-badge"
                    style="animation: logoDot 7s infinite; animation-delay: 5s;"
                    v-if="app.config.production === false">
                    {{ app.config.envName || 'BETA' }}
                </span>
            </router-link>

            <Search />

            <div id="user">
                <a id="current-project" @click.stop="openProjectsMenu()">
                    {{ $store.state.currentProject.title ? $store.state.currentProject.title : _('project.single') }}
                </a>

                <div id="pb" @click.stop="openUserMenu()">
                    <span class="letter">
                        {{ $store.state.user.first_name ? $store.state.user.first_name[0].toUpperCase() : "-" }}
                    </span>
                </div>


                <ProjectMenu ref="projectMenu" />
                <UserMenu ref="userMenu" />
            </div>
        </div>
        <ProjectSettingsModal :project="{...$store.state.currentProject}" ref="projectSettingsModal"/>
    </div>
</template>

<script>
import Sidebar from '@/components/navigation/sidebar/Sidebar.vue'
import ProjectSettingsModal from '@/components/projects/settings/ProjectSettingsModal'
import Search from '@/components/navigation/Search.vue'
import eventBus from '@/eventBus'
import LsIcon from '@/components/icons/LSIcon'
import UserMenu from "@/components/navigation/menus/UserMenu.vue";
import ProjectMenu from "@/components/navigation/menus/ProjectMenu.vue";

export default {
    data: function () {
        return {
            mobile: false,
            manuallyOpened: false
        }
    },
    mounted() {
        this.checkMobile()
        window.addEventListener('resize', () => {
            this.checkMobile()
        });

        document.body.addEventListener('click', () => {
            this.$refs.userMenu?.close()
            this.$refs.projectMenu?.close()
        })

        this.$refs.sidebar.$on('manuallyOpenedChanged', manuallyOpened => {
            this.manuallyOpened = manuallyOpened
        })

        eventBus.$on('openProjectSettingsModal', tab => {
            this.$refs.projectSettingsModal.open(tab)
        })
    },
    watch: {
        $route() {
            if (this.projectMenuOpened) {
                this.projectMenuOpened = false
            }
        }
    },
    methods: {
        checkMobile() {
            this.mobile = window.innerWidth < 720
        },
        openUserMenu() {
            this.$refs.projectMenu.close()
            this.$refs.userMenu.toggle()
        },
        openProjectsMenu() {
            this.$refs.userMenu.close()
            this.$refs.projectMenu.toggle()
        }
    },
    components: {
        ProjectMenu,
        UserMenu,
        LsIcon,
        Sidebar, Search, ProjectSettingsModal
    }
}
</script>
<!-- corresponding scss navigation/navigation.scss -->