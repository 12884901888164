<template>
    <div>
        <div id="sidebar-background" v-if="manuallyOpened && mobile" @click="manuallyOpened = false" :class="{mobile, hideSidebarContents: $route.meta.hideSidebarContents}" />
        <div id="sidebar" v-show="manuallyOpened || !mobile" :key="`sidebar-opened-${manuallyOpened ? 't' : 'f'}-${mobile ? 't' : 'f'}`">
            <div id="sidebar-actions">
                <div id="navigation-icons">
                    <router-link @click.native="urlChange" :to="{name: 'project', params: {id: $store.state.currentProject.id}}" :class="{selected: routeCategory == 'home'}">
                        <i class="uil uil-estate" />
                        <span class="tooltip">{{ _('overview._') }}</span>
                    </router-link>
                    <router-link @click.native="urlChange" :to="{name: 'servers'}" :class="{selected: routeCategory == 'servers'}">
                        <!--<i class="uil uil-cube" />-->
                        <i class="uil uil-servers" />
                        <span class="tooltip">{{ _('servers.plural') }}</span>
                    </router-link>

                    <a v-if="!$route.meta.hideSidebarContents" @click="$store.commit('toggleSideNavClosed')"
                       class="sidenav-visibility-switch">
                        <!--  Animated Sidenav-Collapse button  -->
                        <sidenav-collapse-icon :collapsed="$store.state.app.sideNavClosed" />
                    </a>
                </div>
            </div>
            <div id="sidebar-contents" v-if="!$route.meta.hideSidebarContents"
                 :style="{left: $store.state.app.sideNavClosed ? '-300px' : ''}">
                <ServersProductEntry @urlChange="urlChange" v-if="category == 'servers'"/>
                <DomainsProductEntry @urlChange="urlChange" v-else-if="category == 'domains'"/>
            </div>
        </div>
    </div>
</template>
<script>
import DomainsProductEntry from '@/components/navigation/sidebar/DomainsProductEntry.vue'
import ServersProductEntry from '@/components/navigation/sidebar/ServersProductEntry.vue'
import SidenavCollapseIcon from '@/components/icons/SidenavCollapseIcon.vue'

export default {
    data: () => ({
        category: ""
    }),
    props: {
        mobile: { type: Boolean },
        manuallyOpened: { type: Boolean }
    },
    components: {
        SidenavCollapseIcon,
        DomainsProductEntry,
        ServersProductEntry
    },
    watch: {
        '$route'(to) {
            if (this.mobile) {
                if (['home', 'domain', 'server'].includes(this.$route.name)) {
                    this.$emit('manuallyOpenedChanged', false)
                }
            }
            this.checkRoute(to)
        }
    },
    computed: {
        routeCategory() {
            return this.$route?.meta?.category
        }
    },
    mounted() {
        this.checkRoute(this.$route)
    },
    methods: {
        checkRoute(route) {
            this.isProduct = false
            if (route.meta?.category) {
                this.search = ''
                this.category = route.meta.category
            }
        },
        urlChange() {
            if (this.mobile) {
                this.$emit('manuallyOpenedChanged', false)
            }
        }
    }
}
</script>
