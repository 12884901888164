export default class I18n {
    entries = {}

    useLanguage(entries) {
        this.entries = []
        this.addEntries("", entries)
    }

    addEntries(key, value) {
        if (typeof value == 'string' || typeof value == 'function') {
            this.entries[key] = value
        } else {
            for (let k in value) {
                this.addEntries(key + (key === '' ? '' : '.') + k, value[k])
            }
        }
    }

    get(name, values) {
        if (!this.entries[name]) {
            return name
        }

        let out = this.entries[name]

        if (typeof out === 'function') {
            return out.call(null, ...values)
        }

        for (const key in values) {
            out = out.replaceAll(`{${key}}`, values[key])
        }
        return out
    }
}