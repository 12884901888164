<template>
    <b-row style="margin: 0 -31px">

        <b-col md="3" class="mb-2">
            <router-link class="product-card with-subtitle" :to="{name: 'servers'}">
                <i class="uil uil-servers" />
                <h1>
                    {{ stats.servers }}
                    <span>{{ _('servers.plural') }}</span>
                </h1>
            </router-link>
        </b-col>

        <b-col md="3" class="mb-2">
            <router-link class="product-card with-subtitle" :to="{name: 'servers', hash: '#volumes'}">
                <i class="uil uil-cloud-database-tree" />
                <h1>
                    {{ stats.volumes }}
                    <span>{{ _('volumes.plural') }}</span></h1>
            </router-link>
        </b-col>

        <b-col md="3" class="mb-2">
            <router-link class="product-card with-subtitle" :to="{name: 'servers', hash: '#networks'}">
                <i class="uil uil-data-sharing" />
                <h1>
                    {{ stats.networks }}
                    <span>{{ _('networks.plural') }}</span>
                </h1>
            </router-link>
        </b-col>

        <b-col md="3" class="mb-2">
            <router-link class="product-card with-subtitle" :to="{name: 'servers', hash: '#backups'}">
                <i class="uil uil-history" />
                <h1>
                    {{ stats.backups }}
                    <span>{{ _('servers.backups.plural') }}</span>
                </h1>
            </router-link>
        </b-col>
    </b-row>
</template>
<script>
export default {
    props: {
        stats: {
            type: Object,
            default: () => ({
                networks: 0,
                volumes: 0,
                server: 0,
                backups: 0
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';

.product-card {
    color: $highlightedText;
    border: $borderColor solid 2.5px;
    border-radius: 8px;
    padding: 2px 8px;
    cursor: pointer;
    display: block;

    i {
        font-size: 28px;
        line-height: 0;
        padding: 10px;
        margin: 6px 0;
        vertical-align: middle;
        display: inline-block;
        background: $highlightedTextAlpha;
        border-radius: 40px;
    }

    h1 {
        font-size: 26px;
        vertical-align: middle;
        display: inline-block;
        text-align: center;
        min-width: 35px;
        margin-right: 10px;
        margin-left: 13px;
    }

    &.with-subtitle {
        h1 {
            text-align: left;
            font-size: 22px;

            span {
                opacity: 0.7;
                font-size: 13.5px;
                display: block;
                margin-top: -5px;
            }
        }
    }

    @media screen and (max-width: 1000px) and (min-width: 770px) {
        height: initial;
        i {
            display: block;
            text-align: center;
            background: transparent;
            padding: 5px 0 0;
        }

        h1, &.with-subtitle h1 {
            display: block;
            text-align: center;
            margin: 10px 0 8px;
        }
    }
}
</style>
