console.log(`┌──┬──────────────────────────────────────────┐
│1 │const dev = new Developer(me);            │
│2 │                                          │
│3 │const AWESOME_LANGUAGES = [               │
│4 │   "Java", "JS", "PHP", "TS",             │
│5 │   "Go", "Python", "Rust"                 │
│6 │]                                         │
│7 │                                          │
│8 │if (                                      │
│9 │    dev.canAny(AWESOME_LANGUAGES)         │
│10│    && dev.canGit()                       │
│11│    && dev.hasExperience(3, "YEARS")      │
│12│) {                                       │
│13│    await dev.join(LUMASERV, "DE/Koblenz")│
│14│    LUMASERV.gives(dev, [                 │
│15│        "FAIR_WAGES",                     │
│16│        "NICE_STARTUP_ATMOSPHERE",        │
│17│        "OFFICE_NEAR_UNIVERSITY"          │
│18│        "ENERGY_DRINK_FLATRATE" // ;)     │
│19│    ])                                    │
│20│}                                         │
└──┴──────────────────────────────────────────┘

 Sounds good? Visit https://jobs.lumaserv.com
`)