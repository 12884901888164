import router from '@/router'
import eventBus from '@/eventBus'
import LUMASERVAuthClient from '@/api/LUMASERVAuthClient'
import LUMASERVDomainClient from '@/api/LUMASERVDomainClient'
import LUMASERVComputeClient from '@/api/LUMASERVComputeClient'

export const computeApi = new LUMASERVComputeClient(window.APP_CONFIG && window.APP_CONFIG.compute_url ? window.APP_CONFIG.compute_url    : "https://dev.api.lumaserv.dev/compute")
export const domainApi  = new LUMASERVDomainClient(window.APP_CONFIG && window.APP_CONFIG.domain_url ? window.APP_CONFIG.domain_url    : "https://dev.api.lumaserv.dev/domains")
export const authApi    = new LUMASERVAuthClient(window.APP_CONFIG && window.APP_CONFIG.auth_url ? window.APP_CONFIG.auth_url    : "https://dev.auth.lumaserv.dev")
export const billingApi = new LUMASERVAuthClient(window.APP_CONFIG && window.APP_CONFIG.billing_url ? window.APP_CONFIG.billing_url : "https://dev.billing.lumaserv.dev")

const errorInterceptor = async err => {
    for (const e of err.response.data.messages.errors) {
        if (err.response.status === 401) {
            if (!router.currentRoute.meta.loginNotRequired && !window.location.pathname.startsWith("/login")) {
                await router.push({ name: 'login', query: { redirect: window.location.pathname } })
            }
            break;
        }

        if (e.key === 'general.badRequest') {
            for (const fieldName in err.response.data.data) {
                eventBus.$emit('error_toast', `${fieldName}: ${err.response.data.data[fieldName].join(', ')}`)
            }
        } else {
            eventBus.$emit('error_toast', e.message)
        }
    }
    throw err
}

const successInterceptor = res => {
    res.data.messages.infos.forEach(e => {
        eventBus.$emit('toast', {
            text: e.message
        })
    })
    return res
}

computeApi.axios.interceptors.response.use(successInterceptor, errorInterceptor)
domainApi.axios.interceptors.response.use(successInterceptor, errorInterceptor)
authApi.axios.interceptors.response.use(successInterceptor, errorInterceptor)
billingApi.axios.interceptors.response.use(successInterceptor, errorInterceptor)
