<template>
    <VerticalIllustrationModal ref="modal" @formSubmit="createProject">
        <template v-slot:modal>
            <CreateProject ref="createProject" @close="$refs.modal.close()" />
        </template>
        <template v-slot:illustration>
            <img src="/img/illustrations/create.svg" />
        </template>
    </VerticalIllustrationModal>
</template>
<script>
import VerticalIllustrationModal from '@/components/modal/VerticalIllustrationModal'
import CreateProject from '@/components/projects/CreateProject'

export default {
    name: 'CreateProjectModal',
    components: {
        VerticalIllustrationModal,
        CreateProject
    },
    methods: {
        open() {
            this.$refs.modal.open()
        },
        async createProject() {
            await this.$refs.createProject.create()
            this.$refs.modal.close()
        }
    }
}
</script>
