<template>
    <Modal ref="modal" :show-close-button="false" @formSubmit="submit()" m-width="370px" no-min-width>
        <img class="password-prompt-illustration" src="/img/illustrations/password_hidden.svg">

        <p class="mt-4"><slot/></p>

        <b-input v-model="password" type="password" class="mt-3" autofocus :placeholder="_('user.password')" />

        <template v-slot:bottomFixedMenu>
            <b-button variant="danger" class="float-right" @click="submit()">
                {{ acceptButtonText || _('accept') }}
            </b-button>
            <b-button class="float-right mr-2" @click="close()">{{ _('cancel') }}</b-button>
        </template>
    </Modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'

export default {
    data: () => ({
        password: ''
    }),
    components: {
        Modal,
    },
    props: {
        mTitle: { type: String, default: "" },
        acceptButtonText: { type: String, default: "" }
    },
    mounted() {
        this.$emit('createdComponent', this)

        this.$refs.modal.$on('opened', () => {
            this.$emit('opened')
        })

        this.$refs.modal.$on('closed', () => {
            this.$emit('closed')
        })
    },
    methods: {
        submit() {
            this.$emit('confirmed', this.password)
            this.$refs.modal.close()
        },
        close() {
            this.$emit('rejected')
            this.$refs.modal.close()
        },
        open() {
            this.$emit('closed')
            this.$refs.modal.open()
        }
    }
}
</script>
<style lang="scss" scoped>
p {
    line-height: 26px;
}

.password-prompt-illustration {
    width: calc(100% + 49px);
    margin: auto;
    display: block;
    margin: -25px;
    margin-bottom: 20px;
}
</style>
