<template>
    <div>
        <h1>{{ _('project.members') }}</h1>
        <div class="mt-3 mb-3">
            <div v-for="(user, i) of projectMembers" :key="i" class="member">
                <div class="grid-col profile-picture">
                    <span>{{ user.first_name[0] }}{{ user.last_name[0] }}</span>
                </div>
                <span class="grid-col name">{{ user.first_name }} {{ user.last_name }}</span>

                <div class="grid-col">
                    <span class="role">{{ _(`project.memberRoles.${user.role}`) }}</span>
                </div>

                <div class="grid-col right">
                    <i v-if="user.role != 'OWNER'" @click="removeMember(user.id)" class="uil uil-user-minus icon" />
                </div>
            </div>
        </div>
        <b-button @click="$refs.projectAddMemberModal.open()">{{ _('project.addMember') }}</b-button>

        <ProjectAddMemberModal :project-id="project.id" ref="projectAddMemberModal" />
    </div>
</template>
<script>
import { checkProject } from '@/main'
import ProjectAddMemberModal from '@/components/projects/ProjectAddMemberModal'

export default {
    components: { ProjectAddMemberModal },
    data: () => ({
        projectMembers: []
    }),
    async mounted() {
        await this.loadMembers()
    },
    props: {
        project: { type: Object }
    },
    methods: {
        async loadMembers() {
            this.projectMembers = []
            const members = (await this.authApi.getProjectMembers(this.project.id)).data
            for (const membership of members) {
                const user = (await this.authApi.getUser(membership.user_id)).data
                user.role = membership.role
                this.projectMembers.push(user)
            }
        },
        async removeMember(id) {
            await this.authApi.removeProjectMember(this.project.id, id)
            await this.loadMembers()
            checkProject()
        },
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.member {
    margin-bottom: 10px;

    grid-template-columns: [picture] 35px [name] 1fr [role] 100px [actions] 40px;
    display: grid;
    grid-gap: 20px;


    .profile-picture {
        display: inline-block;
        vertical-align: middle;
        margin-right: 14px;
        padding: 10px;
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }

    .name {
        vertical-align: middle;
        font-size: 18px;
        font-weight: 500;
        display: inline-block;
        color: $highlightedText;
    }

    .role {
        position: absolute;
        right: 0;
        display: inline-block;
        font-size: 14px;
        padding: 2px 5px;
        background: #00000011;
        border-radius: 7px;
        vertical-align: middle;
        font-weight: 500;
        user-select: none;
    }

    .right {
        float: right;
        margin-top: 3px;
        color: $highlightedText;
        text-align: right;

        .icon {
            font-size: 24px;
        }
    }

    .grid-col {
        position: relative;
        display: grid;
        align-content: center;
        justify-content: start;
        align-items: center;
        justify-items: stretch;
    }
}
</style>
