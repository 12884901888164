<template>
    <Modal ref="modal" :m-title="mTitle || _('confirmationQuestion')" :show-close-button="false">
        <p><slot/></p>
        <template v-slot:bottomFixedMenu>
            <b-button variant="danger" class="float-right" @click="$emit('confirmed'); $refs.modal.close()">
                {{ acceptButtonText || _('accept') }}
            </b-button>
            <b-button class="float-right mr-2" @click="$refs.modal.close()">{{ _('cancel') }}</b-button>
        </template>
    </Modal>
</template>
<script>
import Modal from '@/components/modal/Modal.vue'

export default {
    components: {
        Modal,
    },
    props: {
        mTitle: { type: String, default: "" },
        acceptButtonText: { type: String, default: "" }
    },
    mounted() {
        this.$emit('createdComponent', this)

        this.$refs.modal.$on('opened', () => {
            this.$emit('opened')
        })

        this.$refs.modal.$on('closed', () => {
            this.$emit('closed')
        })
    },
    methods: {
        open() {
            this.$refs.modal.open()
        }
    }
}
</script>
<style lang="scss" scoped>
p {
    line-height: 26px;
}
</style>
